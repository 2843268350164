import { BREAKDOWN_DELIMITER, ChartType } from '@src/client/helpers/reports/constants';
import { isPastCompareString, replacePastFlagFromCompareString } from '@src/client/helpers/reports/dataUtils';
import { GenericChartData } from '@src/client/helpers/reports/types';
import { GetReportUsersRequest } from '@src/client/lib/api/types/request';
import { ReportType } from '@src/client/modules/alerts/types';
import dayjs from 'dayjs';

import { D3LineData } from '../charts/d3/line-chart/types';
import { UserViewFunnelStepMetaInfo, UserViewInsightInfo } from './state';

export const DEFAULT_REPORT_USER_LIST_FETCH_SIZE = 10000;

const addPaginationToPayload = (payload: GetReportUsersRequest, currentPage?: number) => {
  const newPayload = { ...payload };
  if (typeof currentPage !== 'undefined') {
    newPayload.params = {
      ...newPayload.params,
      limit: DEFAULT_REPORT_USER_LIST_FETCH_SIZE,
      offset: currentPage * DEFAULT_REPORT_USER_LIST_FETCH_SIZE,
    };
  }
  return newPayload;
};

const createPayloadBase = (configId: string, isCompareMode = false): GetReportUsersRequest => ({
  configId,
  params: {
    is_compare: isCompareMode,
  },
});

const setCommonPayloadParams = (payload: GetReportUsersRequest, key: string, isDateCompareEnabled: boolean) => {
  let updatedKey = key;
  let isCompareMode = false;
  if (isDateCompareEnabled && isPastCompareString(updatedKey)) {
    isCompareMode = true;
    updatedKey = updatedKey.replace(' (Past)', '');
  }

  const [selectedDimension, ...selectedBreakdown] = updatedKey.split(BREAKDOWN_DELIMITER);
  return {
    ...payload,
    params: {
      ...payload.params,
      is_compare: isCompareMode,
      users_selected_dimension: selectedDimension,
      users_selected_breakdown: selectedBreakdown,
    },
  };
};

export const getUserViewApiPayloadForFunnel = (
  metaInfo: UserViewFunnelStepMetaInfo,
  additionalInfo: { configId: string; isDateCompareEnabled: boolean; currentPage?: number },
) => {
  let usersStep = metaInfo.stepData.event;
  let isCompareMode = false;

  if (usersStep) {
    if (additionalInfo.isDateCompareEnabled && isPastCompareString(usersStep)) {
      usersStep = replacePastFlagFromCompareString(usersStep);
      isCompareMode = true;
    }
  }

  let payload = createPayloadBase(additionalInfo.configId, isCompareMode);
  payload = {
    ...payload,
    params: {
      ...payload.params,
      users_step: usersStep,
      is_drop_off: metaInfo.isDropoff,
      combination_selection: metaInfo.stepCombination,
    },
  };

  return addPaginationToPayload(payload, additionalInfo.currentPage);
};

export const getUserViewApiPayloadForInsight = (
  userViewInsightInfo: UserViewInsightInfo,
  additionalInfo: { configId: string; isDateCompareEnabled: boolean; currentPage?: number },
) => {
  let payload = createPayloadBase(additionalInfo.configId);
  const { chartType, data } = userViewInsightInfo;

  switch (chartType) {
    case ChartType.LINE: {
      const lineData = data as D3LineData;
      payload = {
        ...payload,
        params: {
          ...payload.params,
          users_selected_timestamp: dayjs.unix(lineData.date / 1000).format('YYYY-MM-DDTHH:mm:ssZ'),
        },
      };
      payload = setCommonPayloadParams(payload, lineData.key, additionalInfo.isDateCompareEnabled);
      break;
    }
    case ChartType.BAR:
    case ChartType.PIE:
    case ChartType.METRIC: {
      const genericData = data as GenericChartData;
      payload = setCommonPayloadParams(payload, genericData.key, additionalInfo.isDateCompareEnabled);
      break;
    }
    default:
      throw new Error(`Unsupported chart type: ${chartType}`);
  }

  return addPaginationToPayload(payload, additionalInfo.currentPage);
};

export const getApiPayloadForUserListApi = (
  reportType: ReportType,
  metaInfo: UserViewFunnelStepMetaInfo,
  userViewInsightInfo: UserViewInsightInfo,
  additionalInfo: { configId: string; isDateCompareEnabled: boolean; currentPage?: number },
) => {
  switch (reportType) {
    case ReportType.FUNNEL:
      return getUserViewApiPayloadForFunnel(metaInfo, additionalInfo);
    case ReportType.INSIGHT:
      return getUserViewApiPayloadForInsight(userViewInsightInfo, additionalInfo);
    default:
      throw new Error(`User view fetch not implemented for: ${reportType}`);
  }
};
