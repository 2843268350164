/* eslint-disable class-methods-use-this */
import Percept, { PerceptUserPropertyKeys } from '@perceptinsight/percept-js';
import { UIStoragKey } from '@src/client/ui-library/theme-provider/constants';

import { version } from '../../../../package.json';
import { Cookie } from '../api/constants';
import { UserInfo } from '../api/types/response';
import { ErrorTags, EventNames, EventProperty } from './events';

const { VITE_PERCEPT_KEY, VITE_PERCEPT_TEST_KEY, VITE_UDAAN_ENV } = import.meta.env;

const ENVS_TO_IGNORE = ['local', 'dev'];

const USER_PROPS_TO_TRACK = [
  EventProperty.Email,
  EventProperty.FirstName,
  EventProperty.LastName,
  EventProperty.PrivilegeLevel,
  EventProperty.RoleName,
];
class TrackerClass {
  isInitialized = false;

  activeUserData: UserInfo | undefined;

  currentScreentName = '';

  init() {
    if (this.isInitialized) return;
    if (ENVS_TO_IGNORE.includes(VITE_UDAAN_ENV)) return;

    Percept.init(['dev', 'local'].includes(VITE_UDAAN_ENV) ? `${VITE_PERCEPT_TEST_KEY}` : VITE_PERCEPT_KEY, {
      autoTrackAppOpened: true,
      autoTrackPageviews: true,
      autoTrackRuntimeErrors: true,
      autoTrackApiCalls: true,
      apiTrackingBlocklist: ['login.microsoftonline.com'],
      autoCaptureClicks: true,
      enableExperiment: true,
      // TODO: Increase the interval once we start receiving higher traffic
      experimentRefetchIntervalMin: 5,
      enableLogs: false,
      enableHeatmap: true,
      enableHotspot: true,
    });

    this.updateWorkspaceAndTenant();
    this.isInitialized = true;
    this.trackEvent(EventNames.APP_OPEN);
    this.addViteErrorListener();
  }

  updateWorkspaceAndTenant = () => {
    const currentWorkspace = localStorage.getItem(Cookie.workspace_id);
    const currentTenant = localStorage.getItem(Cookie.tenant_id);
    const currentWorkspaceName = localStorage.getItem(Cookie.workspace_name);
    if (currentWorkspace) Percept.setGlobalProperty(EventProperty.Workspace, currentWorkspace);
    if (currentTenant) Percept.setGlobalProperty(EventProperty.Tenant, currentTenant);
    if (currentWorkspaceName) Percept.setGlobalProperty(EventProperty.WorkspaceName, currentWorkspaceName);
  };

  addViteErrorListener = () => {
    try {
      window.addEventListener('vite:preloadError', (_event) => {
        console.error('[PI] vite:preloadError', _event);
        this.trackEvent(EventNames.VITE_PRELOAD_ERROR, {}, { payload: (_event as any).payload });
        window.location.reload();
      });
    } catch (error) {
      console.error('[PI] error adding vite preload error listener ', error);
    }
  };

  setUserId(userId: string, userProps: Record<string, string> = {}) {
    Percept.setUserId(userId, userProps);
  }

  setUserInfo(userInfo: UserInfo) {
    if (!this.isInitialized) return;
    this.activeUserData = userInfo;
    Percept.setUserId(userInfo.userId, {
      [PerceptUserPropertyKeys.EMAIL]: userInfo.email,
      [PerceptUserPropertyKeys.NAME]: `${userInfo.firstName} ${userInfo.lastName}`,
      roleName: userInfo.roleName,
      privilegeLevel: userInfo.privilegeLevel,
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
    });

    Object.keys(userInfo)
      .filter((k) => USER_PROPS_TO_TRACK.includes(k as any))
      .forEach((key) => {
        const value = userInfo[key as keyof UserInfo]?.toString() ?? '';
        this.setGlobalProperty(key as unknown as EventProperty, value);
      });
  }

  setCurrentScreenName(screenName: string) {
    this.currentScreentName = screenName;
  }

  getCurrentScreenName() {
    return this.currentScreentName;
  }

  setGlobalProperty(propertyKey: EventProperty, value: string) {
    if (!this.isInitialized) return;
    Percept.setGlobalProperty(propertyKey as unknown as string, value);
  }

  trackError(error: Error, tag: ErrorTags, extraProps?: Record<string, unknown>) {
    this.trackEvent(
      EventNames.ERROR,
      {
        [EventProperty.ErrorStack]: error?.stack?.toString(),
        [EventProperty.ErrorMessage]: error?.message,
        [EventProperty.ErrorTag]: tag,
      },
      extraProps,
    );
  }

  trackEvent(
    eventName: EventNames,
    properties?: Partial<Record<EventProperty, unknown>>,
    untypedProps?: Record<string, unknown>,
  ) {
    if (!this.isInitialized) return;
    const propsToCapture = {
      ...(properties ?? {}),
      ...(untypedProps ?? {}),
      screen: this.currentScreentName,
      path: location.pathname, // eslint-disable-line  no-restricted-globals
      env: VITE_UDAAN_ENV,
      appVersion: version,
      uiVersion: window.localStorage.getItem(UIStoragKey),
    };
    Percept.capture(eventName, {
      ...propsToCapture,
    });
  }

  logout() {
    if (!this.isInitialized) return;
    this.trackEvent(EventNames.LOGOUT);
    Percept.clear();
  }
}

const Tracker = new TrackerClass();

export default Tracker;
