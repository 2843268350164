import { activeWorkspaceState, userInfoState } from '@src/client/recoil/atoms';
import {
  DependencyList,
  EffectCallback,
  RefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import { globalPageLoaderState } from '../components/global-page-loader';
import { DEMO_WORKSPACE_ID, ELEVATED_ACCESS_ROLES } from '../components/site-navbar/constants';
import { ErrorTags } from '../lib/analytics/events';
import Tracker from '../lib/analytics/tracker';
import { updateLastActiveWorkspaceId } from '../lib/api/mutations/common';
import { getUserInfoOld } from '../lib/api/queries/common';
import { UserInfo, Workspace } from '../lib/api/types/response';
import { saveActiveWorkspaceDetailsInLocalStorage } from '../lib/utils';
import { logoutHandler, redirectToLogin } from '../modules/login/utils';
import { useUIVersion } from '../ui-library/theme-provider/uiProvider';
import { useToast } from '../ui-library/toast/use-toast';

export const useLayoutTopPaddingBasedOnUiVersion = () => {
  const { uiVersion } = useUIVersion();

  return uiVersion === 'v3' ? 'top-0' : 'top-navbar';
};

export function usePrevious<T>(value: T): T | undefined {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref: any = useRef<T>();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

export function useNavigationLinkWithWorkspace() {
  const activeWorkspace = useRecoilValue(activeWorkspaceState);

  const getLinkWithWorkspace = (path: string) => `/${activeWorkspace?.id}/${path}`;

  return {
    getLinkWithWorkspace,
  };
}

export function useOnScreen(ref: RefObject<HTMLElement>): boolean {
  const observerRef = useRef<IntersectionObserver | null>(null);
  const [isOnScreen, setIsOnScreen] = useState(false);

  useLayoutEffect(() => {
    const observerCallback = (entries: IntersectionObserverEntry[]) => {
      const entry = entries[0];
      setIsOnScreen(entry.isIntersecting);
    };

    const createObserver = () => {
      observerRef.current = new IntersectionObserver(observerCallback);
      if (ref?.current) {
        observerRef?.current.observe(ref.current);
      }
    };

    // Delay to ensure layout is stabilized
    const timeoutId = setTimeout(createObserver, 100);

    return () => {
      clearTimeout(timeoutId);
      observerRef?.current?.disconnect();
    };
  }, [ref]);

  return isOnScreen;
}

export function useEffectAfterMount(cb: EffectCallback, dependencies: DependencyList | undefined) {
  const mounted = useRef(true);

  useEffect(() => {
    if (!mounted.current) {
      return cb();
    }
    mounted.current = false;

    return () => {};
  }, dependencies); // eslint-disable-line react-hooks/exhaustive-deps
}

export function useIsFlowDisabled() {
  const userInfo = useRecoilValue(userInfoState);
  return userInfo?.activeWorkspaceId?.includes('udaan');
}

export function useIsDateRangeMoreThanWeekDisabled() {
  return false;
}

export function useIsExperimentEnabled() {
  const userInfo = useRecoilValue(userInfoState);
  const workspaceIds = ['perceptpixel', 'percepttest', 'percept-webapp', 'saasboost'];

  return workspaceIds.some((id) => userInfo?.activeWorkspaceId?.includes(id));
}

export function useIsFeatureFlagEnabled() {
  const userInfo = useRecoilValue(userInfoState);
  const workspaceIds = ['perceptpixel', 'percepttest', 'percept-webapp'];

  return workspaceIds.some((id) => userInfo?.activeWorkspaceId?.includes(id));
}

export function useIsHeatMapEnabled() {
  const userInfo = useRecoilValue(userInfoState);
  const workspaceIds = ['perceptpixel', 'percepttest', 'percept-webapp'];

  return workspaceIds.some((id) => userInfo?.activeWorkspaceId?.includes(id));
}

export function useIsCampaignReminderEnabled() {
  const userInfo = useRecoilValue(userInfoState);
  return userInfo?.activeWorkspaceId?.includes('perceptpixel');
}

export function useIsCampaignFirstTimeFilterEnabled() {
  const userInfo = useRecoilValue(userInfoState);
  return userInfo?.activeWorkspaceId?.includes('perceptpixel');
}

export function useIsPerceptPixelWorkspace() {
  const userInfo = useRecoilValue(userInfoState);
  return userInfo?.activeWorkspaceId?.includes('perceptpixel');
}

export function useIsSupermoneyWorkspace() {
  const userInfo = useRecoilValue(userInfoState);
  return userInfo?.activeWorkspaceId?.includes('supermoney');
}

export function useIsSupermoneyLendingWorkspace() {
  const userInfo = useRecoilValue(userInfoState);
  return userInfo?.activeWorkspaceId?.includes('supermoneylending');
}

export function useIsSupermoneyTenant() {
  const userInfo = useRecoilValue(userInfoState);
  return userInfo?.activeTenantId?.includes('supermoney');
}

export function useIsEkacareTenant() {
  const userInfo = useRecoilValue(userInfoState);
  return userInfo?.activeTenantId?.includes('ekacare');
}

export function useIsRaptoriseTenant() {
  const userInfo = useRecoilValue(userInfoState);
  return userInfo?.activeTenantId?.includes('raptorise');
}

export function useIsDemoWorkspace() {
  const userInfo = useRecoilValue(userInfoState);
  const pathArr = window.location.href.split('/');
  return pathArr[2].includes('demo') || userInfo?.activeWorkspaceId?.includes(DEMO_WORKSPACE_ID);
}

export function useIsWorkspaceCreationEnabled() {
  const userInfo = useRecoilValue(userInfoState);
  const pathArr = window.location.href.split('/');
  const isDemoWorkspace = pathArr[2].includes('demo') || userInfo?.activeWorkspaceId?.includes('demo-ecommerce-app');
  const isElevatedAccess = userInfo && ELEVATED_ACCESS_ROLES.includes(userInfo.roleName);
  return isDemoWorkspace || isElevatedAccess;
}

interface ResizableElementDimensions {
  width: number;
  height: number;
}

export function useResizableElement(elementRef: React.RefObject<HTMLElement>): ResizableElementDimensions {
  const [dimensions, setDimensions] = useState<ResizableElementDimensions>({
    width: 0,
    height: 0,
  });

  const handleResize = useCallback(() => {
    if (elementRef.current) {
      const { clientWidth, clientHeight } = elementRef.current;
      setDimensions({
        width: clientWidth,
        height: clientHeight,
      });
    }
  }, [elementRef]);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize, elementRef]);

  return dimensions;
}

export function useWorkspaceSelector(): { (ws: Workspace, redirect?: string): Promise<boolean> } {
  const queryClient = useQueryClient();
  const { toast } = useToast();
  const setShowGlobalPageLoader = useSetRecoilState(globalPageLoaderState);
  const setActiveWorkspace = useSetRecoilState(activeWorkspaceState);
  const updateActiveWorkspaceRequest = useMutation(updateLastActiveWorkspaceId);

  const handleWorkspaceSelection = async (ws: Workspace, redirect?: string): Promise<boolean> => {
    setShowGlobalPageLoader(true);
    try {
      await updateActiveWorkspaceRequest.mutateAsync({ lastActiveWorkspaceId: ws.id });
      setActiveWorkspace(ws);
      saveActiveWorkspaceDetailsInLocalStorage(ws.id, ws.tenantId, ws.name);
      Tracker.updateWorkspaceAndTenant();
      await queryClient.invalidateQueries();
      setShowGlobalPageLoader(false);
      window.location.href = redirect || `/${ws.id}/home`;

      return true;
    } catch (error: any) {
      setShowGlobalPageLoader(false);
      toast({
        variant: 'danger',
        title: 'Workspace switching failed',
      });
      Tracker.trackError(error, ErrorTags.ACTIVE_WORKSPACE_UPDATE_ERROR, {
        workspaceToUpdate: ws.id,
        changingFrom: 'User Settings',
      });
      return false;
    }
  };
  return handleWorkspaceSelection;
}

export const useBypassSdkCheck = () => {
  const userInfo = useRecoilValue(userInfoState);
  return ['supermoney', 'flash', 'scapia', 'slice', 'ekacare', 'udaan'].includes(userInfo?.activeTenantId ?? '');
};

export const useLogout = () => {
  const setShowGlobalPageLoader = useSetRecoilState(globalPageLoaderState);
  const resetUserInfoInState = useResetRecoilState(userInfoState);
  const queryClient = useQueryClient();

  const onSignOut = useCallback(async () => {
    setShowGlobalPageLoader(true);
    resetUserInfoInState();
    await queryClient.invalidateQueries();
    await logoutHandler();
    setShowGlobalPageLoader(false);
    redirectToLogin();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    handleLogout: onSignOut,
  };
};

export const useUserInfoQuery = (props?: {
  bypassUserStateData?: boolean;
  logoutOnError?: boolean;
  onSuccess?: (data: UserInfo) => void;
  onError?: (err: unknown) => void;
}): { userInfo: UserInfo | undefined; isLoading: boolean; error: unknown; refetchUserInfo: () => void } => {
  const [userInfoStateData, setUserInfoInState] = useRecoilState(userInfoState);
  const canFetchUserData = sessionStorage.getItem('first_time_user') !== 'true' && !userInfoStateData; // eslint-disable-line no-underscore-dangle
  const enabled = canFetchUserData || !!props?.bypassUserStateData;
  const { handleLogout } = useLogout();
  const {
    data: userInfoApiData,
    isLoading,
    error,
    refetch: refetchUserInfo,
  } = useQuery(['userInfo', {}], getUserInfoOld, {
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
    enabled,
    onSuccess: (data) => {
      setUserInfoInState(data);
      Tracker.setUserInfo(data);
      if (props?.onSuccess) {
        props.onSuccess(data);
      }
    },
    onError: (err) => {
      setUserInfoInState(undefined);
      if (props?.onError) {
        props.onError(err);
      }
      // if (error instanceof AuthError)
      if (props?.logoutOnError) {
        handleLogout();
      }
    },
  });

  return {
    isLoading,
    error,
    userInfo: userInfoApiData || userInfoStateData,
    refetchUserInfo,
  };
};
