import { ClassNamesConfig, CSSObjectWithLabel, GroupBase } from 'react-select';

import { cn, HoverableClassNames } from '../utils';
import { SelectClassNames } from './types';

export const commonClassNames = <Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
  selectClassNames?: SelectClassNames,
): ClassNamesConfig<Option, IsMulti, Group> => ({
  control: (_state) => cn('border border-border rounded px-2 py-0.5', HoverableClassNames, selectClassNames?.control),
  menu: (_state) =>
    cn(
      'border border-border bg-popover text-popover-foreground shadow-md p-2 mt-2 rounded z-20',
      selectClassNames?.menu,
    ),
  singleValue: (_state) => cn('text-sm font-normal text-foreground-selected', selectClassNames?.singleValue),
  multiValue: (_state) =>
    cn(
      'bg-background border border-border dark:border-0 rounded px-2 py-0.5 select-none text-foreground-selected',
      selectClassNames?.multiValue,
    ),
  multiValueLabel: (_state) => 'text-xs text-foreground font-normal bg-transparent',
  multiValueRemove: (_state) => 'text-foreground',
  placeholder: (_state) => cn('text-sm font-medium text-foreground-light', selectClassNames?.placeholder ?? ''),
  indicatorsContainer: (_state) => cn('text-foreground-selected', selectClassNames?.indicatorsContainer),
  dropdownIndicator: (_state) => cn('', selectClassNames?.dropdownIndicator),
  loadingMessage: (_state) => 'text-sm font-semibold text-foreground',
  container: (state) => cn(state.isDisabled ? 'opacity-50 !cursor-not-allowed' : '', selectClassNames?.container),
  menuList: (_state) => cn('overflow-x-hidden', selectClassNames?.menuList),
});

export const commonStyles = {
  menu: (provided: CSSObjectWithLabel) => ({ ...provided, zIndex: 20 }),
};
