import { CohortEndpoints } from '@src/client/modules/cohorts/api/endpoints';
import { EngageCampaignEndpoints } from '@src/client/modules/engage/campaign/api/endpoints';
import { EngageAttributionEndpoints } from '@src/client/modules/engage/engage-attribution/types';
import { FlexicastEndpoints } from '@src/client/modules/engage/flexicast/api/endpoints';
import { ExperimentEndpoints } from '@src/client/modules/experiments/api/endpoints';
import { FeatureFlagEndpoints } from '@src/client/modules/feature-flags/api/endpoints';
import { GlobalLimitsEndpoints } from '@src/client/modules/global-limits/api/constants';
import { HotspotEndpoints } from '@src/client/modules/hotspots/api/endpoints';
import { OnboardingEndpoints } from '@src/client/modules/login/api/endpoints';
import { SdkConfigsEndpoints } from '@src/client/modules/sdk-configs/api/endpoints';
import { SdkIntegrationEndpoints } from '@src/client/modules/sdk-integration/api/endpoints';
import { UnsubscribeEndpoints } from '@src/client/modules/unsubscribe/api/constants';
import { UserProfileEndpoints } from '@src/client/modules/user-profiles/api/constants';
import { PaymentEndPoints, RewardsEndpoints } from '@src/client/modules/wallet/api/endpoints';
import { HiddenEventsEndpoints } from '@src/client/modules/workspace-settings/api/constants';

export const API_PAGINATION_PAGE_SIZE = 10;

export enum Cookie {
  refresh_token = '_refresh_token',
  id_token = '_id_token',
  next = '_next',
  workspace_id = '_workspace_id',
  tenant_id = '_tenant_id',
  workspace_name = '_workspace_name',
  auth_type = '_auth_type',
  id_token_expiry = '_id_token_expiry',
}

export enum UserInfoEndpoints {
  userScopesList = '/user/accessScopes',
  userInfo = '/user/current',
  updateLoginTenant = '/auth/setTenant',
  allUsers = '/user/all',
  getUsers = '/user/list',
  usersCount = '/user/count',
  role = '/user/{user_id}/role',
  deleteUser = '/user/{user_id}/delete',
  base = '/user/',
  filteredUsers = '/user/search',
}

export enum RoleEndpoints {
  getRoles = '/roles/',
}

export enum TeamEndpoints {
  base = '/teams/',
  teamUsers = '/teams/{team_id}/users',
  teamUsersCount = '/teams/{team_id}/users/count',
  team = '/teams/{team_id}',
  count = '/teams/count',
}

export enum DashboardEndpoints {
  dashboardSummariesList = '/dashboard',
  dashboardByItemExtId = '/dashboard/{item_ext_id}',
  createDashboard = '/dashboard',
  updateDashboard = '/dashboard/{item_ext_id}',
  dashboardCount = '/dashboard/count',
  defaultDashboard = '/dashboard/default',
}

export enum ReportEndpoints {
  dimensions = `/dimensions/listDetailed`,
  dimensionValues = '/dimensions/values/{dimension}',
  searchDimensionValues = '/dimensions/search/{dimension}/{searchString}',
  reportList = '/report/all',
  createReport = '/report/item',
  initiateRun = '/report/run',
  reportSummariesList = '/report',
  insightByItemExtId = '/report/{item_ext_id}',
  reportCount = '/report/count',
  duplicateReport = '/report/duplicate',
  bulk = '/report/bulk',
  getUsers = '/report/item/users',
  eventProperties = '/dimensions/events/properties',
}

export enum LlmEndpoints {
  llmItem = `/report/llm/item`,
  updateLlmFeedback = `/report/llm/feedback`,
}

// export enum CohortEndpoints {
//   cohortList = '/cohort/all',
//   createCohort = '/cohort/item',
//   initiateRun = '/cohort/run',
//   cohortSummariesList = '/cohort',
//   cohortByItemExtId = '/cohort/{item_ext_id}',
//   basicList = '/cohort/list',
//   cohortCount = '/cohort/count',
//   cohortUserCount = '/cohort/{item_ext_id}/count',
//   uploadCsvFile = '/cohort/item/file',
//   downloadCsvFile = '/cohort/{item_ext_id}/download',
//   createUsersCohort = '/cohort/item/users',
// }

export enum UserEndpoints {
  myReports = '/user/my_reports',
  recentItems = '/user/recents',
  login = '/auth/login',
  refresh = '/auth/refresh',
  userInfo = '/user/',
  authorize = '/auth/authorize',
  signup = '/auth/signup',
  updateLastActiveWorkspaceId = '/user/last_active_workspace',
  recentlyViewed = '/user/recently-viewed',
  tenants = '/tenant',
}

export enum FunnelEndpoints {
  funnelSummariesList = '/funnel',
}

export enum RunEndpoints {
  runStatus = '/run/{runId}',
  runResult = '/run/result',
}

export enum DataItemEndpoints {
  userInfo = '/data_platform/users/getUserInfo',
}

export enum SearchEndpoints {
  searchItems = '/search',
}

export enum EventEndpoints {
  eventByItem = '/event/item',
  eventByItemExtId = '/event/item/{item_ext_id}',
  eventProperties = '/event/properties',
  fetch = '/event/search',
}

export enum AlertEndpoints {
  alertsForReport = '/report/alerts',
  alertCountForReport = '/report/alerts/count',
  anomaliesForReport = '/report/anomalies',
  addAlert = '/alert/item',
  byItemExtId = '/alert/{item_ext_id}',
  byItemId = '/alert/item/{item_id}',
  summaryByItemId = '/alert/item/{item_id}/summary',
  alertList = '/alert/list',
  alertListCount = '/alert/list/count',
  byAnomalyId = '/alert/anomaly/{anomaly_id}',
  updateDashboardReportList = '/alert/update/{item_ext_id}/dashboardReportList',
}

export enum CustomEventsEndpoints {
  count = '/custom/count',
  customEventsList = '/custom',
  eventByItemExtId = '/custom/{item_ext_id}',
  create = '/custom',
  checkNameAvailability = '/custom/checkNameAvailability',
}

export enum DimensionEndpoints {
  events = '/dimensions/',
  eventProperties = '/dimensions/event-properties',
  dimension = '/dimensions/dimension/{dimension_id}',
  event = '/dimensions/event/{event_id}',
}

export enum DataGovernanceEndpoints {
  dimensionAccess = '/data-gov/dimension/{dimension_id}/access',
  dataGovEnabled = '/workspace/data-gov/status',
  isWorkspaceAvailable = '/workspace/is-available',
}

export type APIEndpoints =
  | UserInfoEndpoints
  | RoleEndpoints
  | TeamEndpoints
  | DataItemEndpoints
  | ReportEndpoints
  | RunEndpoints
  | UserEndpoints
  | CohortEndpoints
  | DashboardEndpoints
  | SearchEndpoints
  | EventEndpoints
  | AlertEndpoints
  | CustomEventsEndpoints
  | LlmEndpoints
  | DimensionEndpoints
  | DataGovernanceEndpoints
  | FlexicastEndpoints
  | EngageCampaignEndpoints
  | GlobalLimitsEndpoints
  | UserProfileEndpoints
  | UnsubscribeEndpoints
  | ExperimentEndpoints
  | FeatureFlagEndpoints
  | EngageAttributionEndpoints
  | SdkIntegrationEndpoints
  | RewardsEndpoints
  | OnboardingEndpoints
  | HotspotEndpoints
  | HiddenEventsEndpoints
  | PaymentEndPoints
  | SdkConfigsEndpoints;
