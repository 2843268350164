import { useIsWorkspaceCreationEnabled, useWorkspaceSelector } from '@src/client/hooks';
import { activeWorkspaceState, userInfoState } from '@src/client/recoil/atoms';
import { DropdownMenuItem, DropdownMenuSeparator } from '@src/client/ui-library/dropdown';
import { HoverableDropdown } from '@src/client/ui-library/dropdown/HoverableDropdown';
import { cn } from '@src/client/ui-library/utils';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import LucidIconWrapper from '../lucid-icon-wrapper';
import { DEMO_WORKSPACE_ID } from '../site-navbar/constants';
import { sortWorkspaces } from '../site-navbar/utils';
import { SidepanelNavLinkClassNames } from './constants';

function RenderWorkspaceIcon() {
  const activeWorkspace = useRecoilValue(activeWorkspaceState);

  return (
    <div className="flex justify-center items-center">
      {activeWorkspace?.logoUrl && activeWorkspace?.logoUrl !== 'https://percept.udaan.io/images/pi_logo_color.svg' ? (
        <img src={activeWorkspace.logoUrl} className="h-6 w-6" alt={`${activeWorkspace?.name} logo`} />
      ) : (
        <LucidIconWrapper name="briefcase" />
      )}
    </div>
  );
}

export default function WorkspaceSelectorNew({ isNavigationSidepanelOpen }: { isNavigationSidepanelOpen: boolean }) {
  const userInfo = useRecoilValue(userInfoState);
  const activeWorkspace = useRecoilValue(activeWorkspaceState);
  const handleWorkspaceSelection = useWorkspaceSelector();
  const navigate = useNavigate();
  const isWorkspaceCreationEnabled = useIsWorkspaceCreationEnabled();

  if (!userInfo?.workspaces || !userInfo?.activeWorkspaceId) return null;

  return (
    <HoverableDropdown
      testId="workspace-selector-button"
      trigger={
        isNavigationSidepanelOpen ? (
          <div className="flex items-center justify-between w-full">
            <div className="inline-flex items-center space-x-2">
              <RenderWorkspaceIcon />
              <p className="text-sm font-medium">{activeWorkspace?.name}</p>
            </div>
            <LucidIconWrapper name="chevron-right" />
          </div>
        ) : (
          <RenderWorkspaceIcon />
        )
      }
      classNames={{
        trigger: cn(
          SidepanelNavLinkClassNames,
          isNavigationSidepanelOpen ? 'py-2 px-5 ' : 'px-0.5 py-2 justify-center ',
          'w-full bg-transparent data-[state=open]:text-foreground dark:data-[state=open]:text-foreground rounded-none',
        ),
        content: 'w-[260px] -ml-1 p-2 max-h-screen shadow-none overflow-y-scroll rounded-l-none',
      }}
    >
      {isWorkspaceCreationEnabled && (
        <>
          <DropdownMenuItem
            key="create-workspace"
            onClick={() => navigate('/create-workspace')}
            className="cursor-pointer rounded-md"
            data-testid="create-workspace-button"
          >
            <div className="p-1 inline-flex items-center space-x-2">
              <LucidIconWrapper name="plus" />
              <p className="font-medium">Create Workspace</p>
            </div>
          </DropdownMenuItem>
          <DropdownMenuSeparator />
        </>
      )}
      {sortWorkspaces(userInfo?.workspaces ?? [])
        .filter((w) => w.id !== DEMO_WORKSPACE_ID)
        .map((workspace, index) => (
          <DropdownMenuItem
            key={workspace.id}
            onClick={() => handleWorkspaceSelection(workspace)}
            className="cursor-pointer rounded-md"
            data-testid={`${workspace.id}-workspace-button`}
          >
            <div className="p-1 inline-flex items-center space-x-2">
              {workspace.logoUrl && workspace.logoUrl !== 'https://percept.udaan.io/images/pi_logo_color.svg' ? (
                <img src={workspace.logoUrl} className="h-6 w-6" alt="workspace logo" />
              ) : (
                <LucidIconWrapper name="briefcase" />
              )}
              <p>{workspace.name}</p>
            </div>
          </DropdownMenuItem>
        ))}
    </HoverableDropdown>
  );
}
