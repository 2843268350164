import { DateRangeEnum } from '@src/client/helpers/reports/constants';
import { getFormattedTimestampProps } from '@src/client/helpers/reports/dataUtils';
import { TimestampProps } from '@src/client/helpers/reports/types';
import dayjs, { Dayjs } from 'dayjs';
import { isEqual } from 'lodash-es';

import { ErrorTags, EventProperty } from './analytics/events';
import Tracker from './analytics/tracker';
import { Cookie } from './api/constants';
import { PlatformItemType } from './api/types/request';
import { ReportType } from './api/types/response';

export const isLengthyArray = (item: any) => Array.isArray(item) && item.length > 0;

export const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();

export const formatPercent = (value: number): string => `${(value * 100).toFixed(2)}%`;

export const formatActualPercent = (value: number): string => `${value.toFixed(2)}%`;

export const formatTime = (seconds: number): string => {
  if (seconds < 60) {
    return `${seconds}s`;
  }
  if (seconds < 3600) {
    const minutes = seconds / 60;
    return `${minutes.toFixed(1)}m`;
  }
  if (seconds < 86400) {
    const hours = seconds / 3600;
    return `${hours.toFixed(1)}h`;
  }
  const days = seconds / 86400;
  return `${days.toFixed(1)}d`;
};

export const toTitleCase = (str: string) =>
  str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()).replaceAll('_', ' ');

export const snakeCaseToTitleCase = (str: any) => {
  const words = str.split('_');
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < words.length; i++) {
    words[i] = toTitleCase(words[i]);
  }
  return words.join(' ');
};

export const getTypeOfFilterPropertyValues = (values: any) => {
  if (typeof values === 'string') {
    return 'string';
  }
  if (Array.isArray(values)) {
    return 'array';
  }
  return typeof values;
};

interface InputObject {
  [key: string]: number | string | InputObject | null;
}

export const roundNumbersInObject = (input: InputObject): InputObject => {
  const output: InputObject = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(input)) {
    if (typeof value === 'number') {
      output[key] = value !== null ? Number(value.toFixed(2)) : null;
    } else if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      output[key] = roundNumbersInObject(value);
    } else {
      output[key] = value;
    }
  }
  return output;
};

export const flattenArrayOfObjects = (
  arr: { [key: string]: any }[],
): {
  [key: string]: any;
} =>
  arr.reduce((result, item) => {
    Object.keys(item).forEach((key) => {
      result[key] = item[key]; // eslint-disable-line no-param-reassign
    });
    return result;
  }, {});

/* // Test
let obj1 = {
  a: 1,
  b: 2,
  c: { foo: 1, bar: 2},
  d: { baz: 1, bat: 2 }
}

let obj2 = {
  b: 2, 
  c: { foo: 1, bar: 'monkey'}, 
  d: { baz: 1, bat: 2 }
  e: 1
}
getObjectDiff(obj1, obj2)
// ["c", "e", "a"] */

export const getDiffPropertiesBetweenTwoObjects = (obj1: any, obj2: any) => {
  const diff = Object.keys(obj1).reduce((result, key) => {
    // eslint-disable-next-line no-prototype-builtins
    if (!obj2.hasOwnProperty(key)) {
      result.push(key);
    } else if (isEqual(obj1[key], obj2[key])) {
      if (key === 'steps') {
        const resultKeyIndex = result.indexOf(key);
        result.splice(resultKeyIndex, 1);
      } else {
        const resultKeyIndex = result.indexOf(key);
        result.splice(resultKeyIndex, 1);
      }
    }
    return result;
  }, Object.keys(obj2));
  return diff;
};

export const isEmptyObject = (obj: object): boolean => {
  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      return false;
    }
  }
  return true;
};

export const hasValidValues = (value: any): boolean => {
  if (!value) return false;

  if (typeof value === 'number' || typeof value === 'boolean' || typeof value === 'string') {
    return true;
  }

  if (Array.isArray(value)) {
    if (!isLengthyArray(value)) return false;

    return value.every((item) => !!item);
  }

  if (typeof value === 'object') {
    if (isEmptyObject(value)) {
      return false;
    }
    return true;
  }

  return false;
};

export const generateHexColor = (email: string) => {
  let hash = 0;
  for (let i = 0; i < email.length; i += 1) {
    hash = email.charCodeAt(i) + (hash * 5 - hash);
  }

  let color = '#';
  for (let i = 0; i < 3; i += 1) {
    const value = Math.floor((hash / 2 ** (i * 8)) % 110) + 110;
    color += `00${value.toString(16)}`.substr(-2);
  }

  return color;
};

export const getNavigationPath = (
  itemType: PlatformItemType,
  reportType: ReportType | null,
  itemExternalId: string,
) => {
  if (itemType === PlatformItemType.REPORT) {
    if (reportType === ReportType.INSIGHT) {
      return `insights/${itemExternalId}`;
    }
    if (reportType === ReportType.FUNNEL) {
      return `funnels/${itemExternalId}`;
    }
    if (reportType === ReportType.FLOW) {
      return `flows/${itemExternalId}`;
    }
    if (reportType === ReportType.RETENTION) {
      return `retention/${itemExternalId}`;
    }
  }
  if (itemType === PlatformItemType.DASHBOARD) {
    return `dashboard/${itemExternalId}`;
  }
  if (itemType === PlatformItemType.COHORT) {
    return `cohort/${itemExternalId}`;
  }
  if (itemType === PlatformItemType.CAMPAIGN) {
    return `engage/${itemExternalId}`;
  }
  if (itemType === PlatformItemType.ALERT) {
    return `alert/${itemExternalId}`;
  }
  return '';
};

export const saveActiveWorkspaceDetailsInLocalStorage = (
  workspaceId: string,
  tenantId: string,
  workspaceName: string,
) => {
  localStorage.setItem(Cookie.workspace_id, workspaceId);
  localStorage.setItem(Cookie.tenant_id, tenantId);
  localStorage.setItem(Cookie.workspace_name, workspaceName);
  Tracker.setGlobalProperty(EventProperty.Workspace, workspaceId);
  Tracker.setGlobalProperty(EventProperty.Tenant, tenantId);
  Tracker.setGlobalProperty(EventProperty.WorkspaceName, workspaceName);
};

export const clearActiveWorkspaceDetailsFromLocalStorage = () => {
  localStorage.removeItem(Cookie.workspace_id);
  localStorage.removeItem(Cookie.tenant_id);
};

export const isDemoHomepage = () => {
  const pathArr = window.location.href.split('/');
  return pathArr[2].includes('demo') && pathArr[4].includes('home');
};

export const isDemoUrl = () => {
  const pathArr = window.location.href.split('/');
  return pathArr[2].includes('demo');
};

export const safeRunBlock = async (cb: () => Promise<void> | void, errorTag: ErrorTags) => {
  try {
    await cb();
  } catch (error: any) {
    console.log(error);
    Tracker.trackError(error, errorTag);
  }
};

// eslint-disable-next-line no-restricted-globals
export const setLastVisitedpath = () => sessionStorage.setItem('lastVisitedUrl', location.pathname + location.search);

export const getLastVisitedPath = () => sessionStorage.getItem('lastVisitedUrl');

export const resetLastVisitedPath = () => sessionStorage.removeItem('lastVisitedUrl');

export function updateTimestampPropsForReport(timestampProps: TimestampProps): TimestampProps {
  if (timestampProps.date_range_type === DateRangeEnum.CUSTOM) {
    return timestampProps;
  }
  if (timestampProps.date_range_type === DateRangeEnum.SINCE) {
    return getFormattedTimestampProps(
      timestampProps.granularity,
      timestampProps.date_range_type,
      [],
      [dayjs(timestampProps.from_timestamp), dayjs(timestampProps.to_timestamp)],
    );
  }
  return getFormattedTimestampProps(timestampProps.granularity, timestampProps.date_range_type, [], []);
}

export function convertToLabelValue(
  input: string | string[],
): { label: string; value: string } | { label: string; value: string }[] {
  if (typeof input === 'string') {
    // If input is a string, convert it to a single object
    return { label: input, value: input };
  }
  if (Array.isArray(input)) {
    // If input is an array of strings, convert each string to an object
    return input.map((str) => ({ label: str, value: str }));
  }
  // Handle other cases, like null or undefined
  return input;
}

export function convertToValue(
  input: { label: string; value: string } | { label: string; value: string }[],
): string | string[] {
  if (Array.isArray(input)) {
    // If input is an array of objects, extract the 'value' field and return as an array of strings
    return input.map((item) => item.value);
  }
  if (input) {
    // If input is a single object, return the 'value' as a string
    return input.value;
  }
  // Handle other cases, like null or undefined
  return input;
}

export function convertDayJsDateTimeToIST(timestamp: Dayjs, endOfDay = false): string {
  // NOTE: Don't use miliseconds EVER in this. Both constructor and UI breaks
  return endOfDay ? timestamp.format('YYYY-MM-DDT23:59:59Z') : timestamp.format('YYYY-MM-DDT00:00:00Z');
}

export const capitalizeFirstChar = (str: string): string => {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);
};

export const formatLargeNumber = (numToFormat: number) => {
  const formatter = Intl.NumberFormat('en', { notation: 'compact' });

  return formatter.format(numToFormat);
};

export const capitalizeFirstCharOnly = (str: string): string => {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
};
