import { DropdownMenuLabel } from '@radix-ui/react-dropdown-menu';
import { useIsOnboardingCreatedWorkspace } from '@src/client/helpers/reports/hooks';
import { useLogout } from '@src/client/hooks';
import { EventNames } from '@src/client/lib/analytics/events';
import { userInfoState } from '@src/client/recoil/atoms';
import { DropdownMenuSeparator } from '@src/client/ui-library/dropdown';
import { HoverableDropdown } from '@src/client/ui-library/dropdown/HoverableDropdown';
import { cn } from '@src/client/ui-library/utils';
import { useRecoilValue } from 'recoil';

import { SettingsIconV3 } from '../../ui-library/icons/v3';
import LucidIconWrapper from '../lucid-icon-wrapper';
import { ELEVATED_ACCESS_ROLES } from '../site-navbar/constants';
import ThemeToggler from '../site-navbar/ThemeToggler';
import UIVersionToggler from '../site-navbar/UIVersionToggler';
import { SidepanelActiveNavClassNames, SidepanelNavLinkClassNames } from './constants';
import SidepanelNavLink from './SidepanelNavLink';

const elevatedAccessItems = [
  {
    label: 'Users and Teams',
    link: 'settings/manage-users',
    eventName: EventNames.MANAGE_USERS_CLICKED,
    icon: <LucidIconWrapper name="users" />,
  },
  {
    label: 'Data Governance',
    link: 'settings/data-governance',
    eventName: EventNames.DATA_GOVERNANCE_CLICKED,
    icon: <LucidIconWrapper name="shield-ellipsis" />,
  },
  {
    label: 'Engage Settings',
    link: 'engage-settings',
    eventName: EventNames.ENGAGE_SETTINGS_CLICKED,
    icon: <LucidIconWrapper name="captions" />,
  },
  {
    label: 'Workspace Settings',
    link: 'workspace-settings',
    eventName: EventNames.WORKSPACE_SETTINGS_CLICKED,
    icon: <LucidIconWrapper name="briefcase" />,
  },
  // {
  //   label: 'SDK Settings',
  //   link: 'sdk-configs',
  //   eventName: EventNames.SDK_CONFIGS_CLICKED,
  //   icon: <LucidIconWrapper name="settings-2" />,
  // },
];

export default function SettingsMenuNew({ isNavigationSidepanelOpen }: { isNavigationSidepanelOpen: boolean }) {
  const userInfo = useRecoilValue(userInfoState);
  const isElevatedAccess = userInfo && ELEVATED_ACCESS_ROLES.includes(userInfo.roleName);
  const { handleLogout } = useLogout();

  const isWsCreatedViaOnboardlingFlow = useIsOnboardingCreatedWorkspace();
  const isSettingsSubPageOpen = ['settings/manage-users', 'settings/data-governance', 'engage-settings'].some((p) =>
    window.location.pathname.includes(p),
  );
  return (
    <HoverableDropdown
      testId="settings-menu-button"
      trigger={
        isNavigationSidepanelOpen ? (
          <div className="flex items-center justify-between w-full">
            <div className="inline-flex items-center space-x-2">
              <SettingsIconV3 active={isSettingsSubPageOpen} />
              <p className="text-sm font-medium">Settings</p>
            </div>
            <LucidIconWrapper name="chevron-right" />
          </div>
        ) : (
          <SettingsIconV3 active={isSettingsSubPageOpen} />
        )
      }
      classNames={{
        trigger: cn(
          SidepanelNavLinkClassNames,
          isNavigationSidepanelOpen ? 'py-2 px-5 ' : 'px-4 py-2 justify-center ',
          isSettingsSubPageOpen ? SidepanelActiveNavClassNames : '',
          'w-full bg-transparent rounded-none',
        ),
        content: 'w-[260px] -ml-1 p-2 shadow-none rounded-l-none',
      }}
    >
      {isElevatedAccess && (
        <section>
          {elevatedAccessItems.map(({ label, link, icon }) => (
            <div key={link}>
              <SidepanelNavLink isNavigationSidepanelOpen path={link} text={label} icon={icon} />
              <DropdownMenuSeparator className="px-2 my-2" />
            </div>
          ))}
        </section>
      )}
      <DropdownMenuLabel className="flex items-center justify-between mb-2 p-2">
        <p className="text-sm font-medium text-foreground-selected">Dark Mode</p>
        <ThemeToggler />
      </DropdownMenuLabel>
      {isWsCreatedViaOnboardlingFlow ? null : (
        <DropdownMenuLabel className="flex items-center justify-between mb-2 p-2">
          <p className="text-sm font-medium text-foreground-selected">New UI</p>
          <UIVersionToggler />
        </DropdownMenuLabel>
      )}
      <DropdownMenuLabel
        onClick={handleLogout}
        className="flex items-center justify-between p-2 cursor-pointer rounded-md bg-transparent hover:bg-gray-50 dark:hover:bg-foreground-secondary"
      >
        <p className="text-sm font-medium text-foreground-selected">Logout</p>
        <LucidIconWrapper name="log-out" />
      </DropdownMenuLabel>
    </HoverableDropdown>
  );
}
