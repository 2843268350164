import { hasBreakdownValueChanged } from '@src/client/components/filters-and-selectors/breakdown-selector/utils';
import { CustomCompareStateType } from '@src/client/components/filters-and-selectors/compare-selector/atoms';
import { hasDimensionValueChanged } from '@src/client/components/filters-and-selectors/dimension-filter/utils';
import { hasGlobalFiltersChanged } from '@src/client/components/filters-and-selectors/global-property-filter/utils';
import {
  BREAKDOWN_DELIMITER,
  ChartType,
  CompareTimePeriodCustomSubTypes,
  CompareTimePeriodSubTypes,
  CompareType,
  DateRangeEnum,
  DATETIME_FORMAT_WITH_TIMEZONE,
  GranularityEnum,
} from '@src/client/helpers/reports/constants';
import {
  addLineChartData,
  addPastFlagToCompareString,
  getBreakDownStrArray,
  getCompareFormObjFromReportResponse,
  getCustomDateRangeFormObjReportResponse,
  getEndDateFromQueryBuilderConfigForCustomCompare,
  getFilterFormObjFromReportResponse,
  getFormattedChartProps,
  getFormattedCompare,
  getFormattedDimensions,
  getFormattedGlobalFilters,
  getFormattedGroupBy,
  getFormattedTimestampProps,
  getFormObjectForGroupBy,
  getGlobalDimensionFormObjFromInsightResponse,
  getGraphItemColor,
  getSinceDateRangeFormObjReportResponse,
  getStartDateFromQueryBuilderConfigForCustomCompare,
  isBreakdownApplied,
  isFormulaApplied,
  replacePastFlagFromCompareString,
} from '@src/client/helpers/reports/dataUtils';
import {
  Breakdown,
  ChartData,
  DataVizRow,
  DataVizRowAndChart,
  Formula,
  GenericChartData,
  InsightQueryBuilder,
  LineChartData,
} from '@src/client/helpers/reports/types';
import { QueryBuilderTimestampInfo } from '@src/client/helpers/workers/types';
import { ReportItemResponse } from '@src/client/lib/api/types/response';
import { getDiffPropertiesBetweenTwoObjects, hasValidValues, isLengthyArray } from '@src/client/lib/utils';
import dayjs, { ManipulateType } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { isEqual, round } from 'lodash-es';

dayjs.extend(utc);

export const InsightFieldsNotAffectingData = ['insightTitle', 'insightDescription'];

export const shouldRefetchInsightOnParamsChange = (
  builderData: InsightQueryBuilder,
  lastBuilderData: InsightQueryBuilder | undefined,
): boolean => {
  const changedFields = getDiffPropertiesBetweenTwoObjects(builderData, lastBuilderData || {}) as Array<
    keyof InsightQueryBuilder
  >;
  if (
    !isLengthyArray(builderData.dimensions) ||
    isEqual(builderData, lastBuilderData) ||
    isEqual(changedFields, InsightFieldsNotAffectingData)
  ) {
    return false;
  }
  if (isLengthyArray(changedFields)) {
    const shouldFetchReport = changedFields.some((value) => {
      const changedData = builderData[value];

      if (value === 'dimensions') {
        return hasDimensionValueChanged(builderData.dimensions, lastBuilderData?.dimensions);
      }

      if (value === 'breakdowns') return hasBreakdownValueChanged(builderData.breakdowns, lastBuilderData?.breakdowns);

      if (value === 'global-filters')
        return hasGlobalFiltersChanged(builderData['global-filters'], lastBuilderData?.['global-filters']);

      // API data structure for all chart types except Line is same
      if (value === 'chartType') {
        return builderData.chartType === ChartType.LINE || lastBuilderData?.chartType === ChartType.LINE;
      }

      if (value === 'viewMode') {
        return !isEqual(builderData.viewMode, lastBuilderData?.viewMode);
      }

      const isValid =
        !!value &&
        (!!changedData || (!changedData && lastBuilderData?.[value])) &&
        !InsightFieldsNotAffectingData.includes(value) &&
        (hasValidValues(changedData) || (!hasValidValues(changedData) && hasValidValues(lastBuilderData?.[value])));

      return isValid;
    });
    return shouldFetchReport;
  }
  return false;
};

export const getBuilderDataFromInsightResponse = (response: ReportItemResponse): InsightQueryBuilder => {
  const globalFilters = response.params['global-filters'];
  const dimensions = response.params.dimension;
  const { compare } = response.params;
  const breakdowns = response.params.group_by;
  const formvalues: InsightQueryBuilder = {
    insightTitle: response.name,
    insightDescription: response.description,
    viewMode: response.params.chart_props.view_mode,
    chartType: response.params.chart_props.chart_type,
    dateRange: response.params.timestamp_props.date_range_type.toLowerCase(),
    formulas: response.params.formula,
    granularity: response.params.timestamp_props ? response.params.timestamp_props.granularity : null,
    dimensions: dimensions && dimensions.length > 0 ? getGlobalDimensionFormObjFromInsightResponse(dimensions) : [],
    approximateValue: true,
  };
  if (response.params.timestamp_props.date_range_type === DateRangeEnum.CUSTOM) {
    formvalues.customDateRange = getCustomDateRangeFormObjReportResponse(response.params.timestamp_props);
  }
  if (response.params.timestamp_props.date_range_type === DateRangeEnum.SINCE) {
    formvalues.sinceDateRange = getSinceDateRangeFormObjReportResponse(response.params.timestamp_props);
  }
  if (breakdowns && breakdowns.length > 0) {
    formvalues.breakdowns = getFormObjectForGroupBy(breakdowns);
  }
  if (globalFilters && globalFilters.config && globalFilters.config.length > 0) {
    formvalues['global-filters'] = getFilterFormObjFromReportResponse(globalFilters);
  }
  if (compare) {
    const formattedCustomData = getCompareFormObjFromReportResponse(compare);
    formvalues.compare = formattedCustomData.compareData;
    formvalues.customCompareData = formattedCustomData.customCompareData;
  }
  return formvalues;
};

export const getCreateInsightObjectFromBuilderData = (builderData: InsightQueryBuilder) => {
  const createInsightObject: any = {};
  const params: any = {};
  const itemName = builderData.insightTitle ?? 'Untitled Insight';
  const itemDescription = builderData.insightDescription;
  const { formulas } = builderData;
  const groupBy = builderData.breakdowns;
  const { dimensions } = builderData;
  const globalFilters = builderData['global-filters'];
  const { viewMode } = builderData;
  const { chartType } = builderData;
  const { granularity } = builderData;
  const { dateRange } = builderData;
  const { customDateRange } = builderData;
  const { sinceDateRange } = builderData;
  const { compare } = builderData;
  const { customCompareData } = builderData;
  const { approximateValue } = builderData;

  if (formulas !== undefined && formulas.length > 0) {
    params.formula = formulas;
  }
  if (groupBy !== undefined && groupBy.length > 0) {
    params.group_by = getFormattedGroupBy(groupBy);
  }
  if (dimensions !== undefined && dimensions.length > 0) {
    params.dimension = getFormattedDimensions(dimensions);
  }
  if (globalFilters !== undefined && globalFilters.length > 0) {
    params['global-filters'] = getFormattedGlobalFilters(globalFilters);
  }
  params.approximate_value = approximateValue;
  const chartProps = getFormattedChartProps(viewMode, chartType);
  if (Object.keys(chartProps).length > 0) params.chart_props = chartProps;

  const timestampProps = getFormattedTimestampProps(granularity, dateRange, customDateRange, sinceDateRange);
  params.timestamp_props = timestampProps;
  if (compare !== undefined && compare.length > 0) {
    params.compare = getFormattedCompare(compare, customCompareData);
  }
  params.additional_metrics = { metrics_list: ['average'] };

  createInsightObject.itemName = itemName;
  createInsightObject.itemDescription = itemDescription;
  createInsightObject.itemScope = 'PUBLIC';
  createInsightObject.params = params;

  return createInsightObject;
};

/** Chart and Table Data helpers below */

const addChartData = (
  breakdownColums: any,
  dataPoints: any,
  chartData: ChartData,
  isPastData: boolean,
  chartType: ChartType,
) => {
  const currentChartDataLength = chartData.length;
  const ct = chartType?.toLowerCase();
  if (ct === ChartType.LINE.toLowerCase()) {
    addLineChartData(breakdownColums, dataPoints, chartData, isPastData);
  } else if (
    ct === ChartType.BAR.toLowerCase() ||
    ct === ChartType.PIE.toLowerCase() ||
    ct === ChartType.METRIC.toLowerCase() ||
    ct === ChartType.TABLE.toLowerCase()
  ) {
    const obj = {
      value: round(dataPoints.aggregated_value, 2),
      key: Object.values(breakdownColums).join(BREAKDOWN_DELIMITER),
      color: getGraphItemColor(currentChartDataLength),
    };
    (chartData as GenericChartData[]).push(obj);
  }
};

const getRowsWithoutBreakdownWithoutFormula = (
  data: any,
  rows: DataVizRow[],
  chartData: any[],
  isPastData: boolean,
  chartType: ChartType,
): DataVizRowAndChart => {
  const ct = chartType?.toLowerCase();
  Object.keys(data).forEach((property) => {
    const currentRowsLength = rows.length;
    const eventObj = { event: property };
    let obj: any = {};
    if (ct === ChartType.LINE.toLowerCase()) {
      obj = { ...eventObj, uniqueKey: Object.values(eventObj).join(BREAKDOWN_DELIMITER), ...data[property] };
    } else if (
      ct === ChartType.BAR.toLowerCase() ||
      ct === ChartType.PIE.toLowerCase() ||
      ct === ChartType.METRIC.toLowerCase() ||
      ct === ChartType.TABLE.toLowerCase()
    ) {
      obj = {
        ...eventObj,
        value: round(data[property].aggregated_value, 2),
        uniqueKey: Object.values(eventObj).join(BREAKDOWN_DELIMITER),
      };
    } else {
      console.error('Unsupported chart type');
    }
    obj.key = addPastFlagToCompareString(
      Object.values(eventObj).join(BREAKDOWN_DELIMITER),
      isPastData && ct === ChartType.LINE.toLowerCase(),
    );
    // obj['key'] = Math.round(Math.random() * 1000000)
    rows.push({ ...obj, color: getGraphItemColor(currentRowsLength) });
    addChartData(eventObj, data[property], chartData, isPastData, chartType);
  });
  return { rows, chartData };
};

const getRowsWithoutBreakdownWithFormula = (
  data: any,
  rows: DataVizRow[],
  chartData: any[],
  isPastData: boolean,
  chartType: ChartType,
): DataVizRowAndChart => {
  const ct = chartType?.toLowerCase();
  Object.keys(data).forEach((property) => {
    const currentRowsLength = rows.length;
    const formulaObj = { formula: property };
    let obj: any = {};
    if (ct === ChartType.LINE.toLowerCase()) {
      obj = { ...formulaObj, uniqueKey: Object.values(formulaObj).join(BREAKDOWN_DELIMITER), ...data[property] };
    } else if (
      ct === ChartType.BAR.toLowerCase() ||
      ct === ChartType.PIE.toLowerCase() ||
      ct === ChartType.METRIC.toLowerCase() ||
      ct === ChartType.TABLE.toLowerCase()
    ) {
      obj = {
        ...formulaObj,
        value: round(data[property].aggregated_value, 2),
        uniqueKey: Object.values(formulaObj).join(BREAKDOWN_DELIMITER),
      };
    } else {
      console.log('Unsupported chart type');
    }
    obj.key = addPastFlagToCompareString(
      Object.values(formulaObj).join(BREAKDOWN_DELIMITER),
      isPastData && ct === ChartType.LINE.toLowerCase(),
    );
    // obj['key'] = Math.round(Math.random() * 1000000)
    rows.push({ ...obj, color: getGraphItemColor(currentRowsLength) });
    addChartData(formulaObj, data[property], chartData, isPastData, chartType);
  });
  return { rows, chartData };
};

const getRowsWithBreakdownWithoutFormula = (
  data: any,
  level: number,
  columnObj: any,
  breakdowns: string[],
  rows: DataVizRow[],
  chartData: any[],
  isPastData: boolean,
  chartType: ChartType,
): DataVizRowAndChart => {
  Object.keys(data).forEach((property) => {
    const currentRowsLength = rows.length;
    if (property !== '$overall') {
      if (typeof data[property] === 'object' && data[property] != null) {
        if (level === -1) {
          const obj = {
            event: property,
            ...columnObj,
          };
          getRowsWithBreakdownWithoutFormula(
            data[property],
            level + 1,
            obj,
            breakdowns,
            rows,
            chartData,
            isPastData,
            chartType,
          );
        }
        if (level >= 0 && breakdowns[level]) {
          const breakdownPropertySplitArr = breakdowns[level].split('|');
          const keyName = breakdownPropertySplitArr[0];
          const obj = {
            ...columnObj,
          };
          obj[keyName] = property;
          if (level === breakdowns.length - 1) {
            addChartData(obj, data[property], chartData, isPastData, chartType);
            const ct = chartType?.toLowerCase();
            let mergedObj: any = {};
            if (ct === ChartType.LINE.toLowerCase()) {
              mergedObj = { ...obj, uniqueKey: Object.values(obj).join(BREAKDOWN_DELIMITER), ...data[property] };
            } else if (
              ct === ChartType.BAR.toLowerCase() ||
              ct === ChartType.PIE.toLowerCase() ||
              ct === ChartType.METRIC.toLowerCase() ||
              ct === ChartType.TABLE.toLowerCase()
            ) {
              mergedObj = {
                ...obj,
                value: round(data[property].aggregated_value, 2),
                uniqueKey: Object.values(obj).join(BREAKDOWN_DELIMITER),
              };
            } else {
              console.log('Unsupported chart type');
            }
            mergedObj.key = addPastFlagToCompareString(
              Object.values(obj).join(BREAKDOWN_DELIMITER),
              isPastData && ct === ChartType.LINE.toLowerCase(),
            );
            // mergedObj['key'] = Math.round(Math.random() * 1000000)
            rows.push({ ...mergedObj, color: getGraphItemColor(currentRowsLength) });
          }
          getRowsWithBreakdownWithoutFormula(
            data[property],
            level + 1,
            obj,
            breakdowns,
            rows,
            chartData,
            isPastData,
            chartType,
          );
        }
      }
    }
  });
  return { rows, chartData };
};

const getRowsWithBreakdownWithFormula = (
  data: any,
  level: number,
  columnObj: any,
  breakdowns: string[],
  rows: DataVizRow[],
  chartData: any[],
  isPastData: boolean,
  chartType: ChartType,
): DataVizRowAndChart => {
  Object.keys(data).forEach((property) => {
    const currentRowsLength = rows.length;
    if (property !== '$overall') {
      if (typeof data[property] === 'object' && data[property] != null) {
        if (level === -1) {
          const obj = {
            formula: property,
            ...columnObj,
          };
          getRowsWithBreakdownWithFormula(
            data[property],
            level + 1,
            obj,
            breakdowns,
            rows,
            chartData,
            isPastData,
            chartType,
          );
        }
        if (level >= 0 && breakdowns[level]) {
          const breakdownPropertySplitArr = breakdowns[level].split('|');
          const keyName = breakdownPropertySplitArr[0];
          const obj = {
            ...columnObj,
          };
          obj[keyName] = property;
          if (level === breakdowns.length - 1) {
            addChartData(obj, data[property], chartData, isPastData, chartType);
            const ct = chartType?.toLowerCase();
            let mergedObj: any = {};
            if (ct === ChartType.LINE.toLowerCase()) {
              mergedObj = { ...obj, uniqueKey: Object.values(obj).join(BREAKDOWN_DELIMITER), ...data[property] };
            } else if (
              ct === ChartType.BAR.toLowerCase() ||
              ct === ChartType.PIE.toLowerCase() ||
              ct === ChartType.METRIC.toLowerCase() ||
              ct === ChartType.TABLE.toLowerCase()
            ) {
              mergedObj = {
                ...obj,
                value: round(data[property].aggregated_value, 2),
                uniqueKey: Object.values(obj).join(BREAKDOWN_DELIMITER),
              };
            } else {
              console.log('Unsupported chart type');
            }
            mergedObj.key = addPastFlagToCompareString(
              Object.values(obj).join(BREAKDOWN_DELIMITER),
              isPastData && ct === ChartType.LINE.toLowerCase(),
            );
            // mergedObj['key'] = Math.round(Math.random() * 1000000)
            rows.push({ ...mergedObj, color: getGraphItemColor(currentRowsLength) });
          }
          getRowsWithBreakdownWithFormula(
            data[property],
            level + 1,
            obj,
            breakdowns,
            rows,
            chartData,
            isPastData,
            chartType,
          );
        }
      }
    }
  });
  return { rows, chartData };
};

const getFormattedData = (
  data: any,
  breakdowns: Breakdown[],
  formulas: Formula[],
  isPastData: boolean,
  chartType: ChartType,
): DataVizRowAndChart => {
  if (isBreakdownApplied(breakdowns) && !isFormulaApplied(formulas)) {
    return getRowsWithBreakdownWithoutFormula(
      data,
      -1,
      {},
      getBreakDownStrArray(breakdowns).flat(),
      [],
      [],
      isPastData,
      chartType,
    );
  }
  if (isBreakdownApplied(breakdowns) && isFormulaApplied(formulas)) {
    return getRowsWithBreakdownWithFormula(
      data,
      -1,
      {},
      getBreakDownStrArray(breakdowns).flat(),
      [],
      [],
      isPastData,
      chartType,
    );
  }
  if (!isBreakdownApplied(breakdowns) && isFormulaApplied(formulas)) {
    return getRowsWithoutBreakdownWithFormula(data, [], [], isPastData, chartType);
  }
  if (!isBreakdownApplied(breakdowns) && !isFormulaApplied(formulas)) {
    return getRowsWithoutBreakdownWithoutFormula(data, [], [], isPastData, chartType);
  }
  return { rows: [], chartData: [] };
};

const mergedObjForCompareForLineChartRowData = (
  originalObj: DataVizRow,
  compareObj: DataVizRow,
  timeUnit: ManipulateType | undefined,
  timeValue = 1,
) => {
  const newObj: any = {};
  newObj.color = originalObj.color;
  Object.keys(compareObj).forEach((key) => {
    if (!dayjs(key).isValid() && !(key in newObj)) {
      newObj[key] = compareObj[key];
    }
  });

  Object.keys(originalObj).forEach((key) => {
    if (dayjs(key).isValid()) {
      newObj[key] = originalObj[key];
      // Extract the timezone offset from the key
      const keyOffset = dayjs(key).utcOffset();

      // Subtract 1 TimeUnit from key (for compare)
      const prevDayKey = dayjs(key).subtract(timeValue, timeUnit).utcOffset(keyOffset);

      // Format the offset as +HH:mm
      const formattedOffset = prevDayKey.format('Z');

      // Format the result with the offset
      const prevDayKeyWithOffset = prevDayKey.format(`YYYY-MM-DDTHH:mm:ss.SSS[${formattedOffset}]`);
      const formattedPrevDayKey = addPastFlagToCompareString(prevDayKeyWithOffset, true);

      if (prevDayKeyWithOffset in compareObj) {
        newObj[formattedPrevDayKey] = compareObj[prevDayKeyWithOffset];
      }
    }
  });
  return newObj;
};

const comparePrevDayDataForRows = (
  originalRows: DataVizRow[],
  compareRows: DataVizRow[],
  timeUnit: ManipulateType | undefined,
  timeValue = 1,
) => {
  const rows: any[] = [];
  originalRows.forEach((item) => {
    const compareIndex = compareRows.findIndex((f) => item.key === replacePastFlagFromCompareString(f.key));
    if (compareIndex !== -1) {
      const mergedObj = mergedObjForCompareForLineChartRowData(item, compareRows[compareIndex], timeUnit, timeValue);
      rows.push(mergedObj);
    } else {
      rows.push(item);
    }
  });
  return rows;
};

const getDaysDiffForCustomCompareFlow = (
  customCompareData: CustomCompareStateType,
  timestampData: QueryBuilderTimestampInfo,
): number | undefined => {
  if (customCompareData.type === CompareTimePeriodCustomSubTypes.CUSTOM_START) {
    const reportStartDate = getStartDateFromQueryBuilderConfigForCustomCompare(timestampData);
    if (!reportStartDate || !customCompareData.date) return undefined;

    return Math.abs(dayjs(customCompareData.date).diff(reportStartDate, 'day'));
  }

  if (customCompareData.type === CompareTimePeriodCustomSubTypes.CUSTOM_END) {
    const reportEnd = getEndDateFromQueryBuilderConfigForCustomCompare(timestampData);
    if (!reportEnd || !customCompareData.date) return undefined;

    return Math.abs(dayjs(customCompareData.date).diff(reportEnd, 'day'));
  }

  return undefined;
};

export const mergeRowsForLineChart = (
  originalRows: DataVizRow[],
  compareRows: DataVizRow[],
  compareOption: string[],
  customCompareData: CustomCompareStateType | undefined,
  timestampData: QueryBuilderTimestampInfo | undefined,
) => {
  if (compareOption[0] === CompareType.TIME_PERIOD && compareOption[1] === CompareTimePeriodSubTypes.PERVIOUS_DAY) {
    const rows = comparePrevDayDataForRows(originalRows, compareRows, 'day');
    return rows;
  }
  if (compareOption[0] === CompareType.TIME_PERIOD && compareOption[1] === CompareTimePeriodSubTypes.PERVIOUS_WEEK) {
    const rows = comparePrevDayDataForRows(originalRows, compareRows, 'week');
    return rows;
  }
  if (compareOption[0] === CompareType.TIME_PERIOD && compareOption[1] === CompareTimePeriodSubTypes.PERVIOUS_MONTH) {
    const rows = comparePrevDayDataForRows(originalRows, compareRows, 'month');
    return rows;
  }

  if (
    compareOption[0] === CompareType.TIME_PERIOD &&
    compareOption[1] === CompareTimePeriodSubTypes.CUSTOM &&
    customCompareData?.date &&
    customCompareData?.type &&
    timestampData
  ) {
    const daysDiff = getDaysDiffForCustomCompareFlow(customCompareData, timestampData);
    if (!daysDiff) return [];
    const rows = comparePrevDayDataForRows(originalRows, compareRows, 'day', daysDiff);
    return rows;
  }
  return [];
};

const mergeRowsForBarAndMetricChart = (originalRows: DataVizRow[], compareRows: DataVizRow[]) => {
  const mergedArray: any = [];
  originalRows.forEach((o) => {
    const compareDataItem = compareRows.find((c) => c.uniqueKey === o.uniqueKey);
    const obj = {
      ...o,
      'value(Past)': compareDataItem?.value,
    };
    mergedArray.push(obj);
  });
  return mergedArray;
};

const mergeOriginalAndCompareRows = (
  originalRows: DataVizRow[],
  compareRows: DataVizRow[],
  compareOption: string[],
  chartType: string,
  customCompareData: CustomCompareStateType | undefined,
  timestampData: QueryBuilderTimestampInfo | undefined,
) => {
  const ct = chartType?.toLowerCase();
  if (ct === ChartType.LINE.toLowerCase()) {
    return mergeRowsForLineChart(originalRows, compareRows, compareOption, customCompareData, timestampData);
  }
  if (
    ct === ChartType.BAR.toLowerCase() ||
    ct === ChartType.METRIC.toLowerCase() ||
    ct === ChartType.TABLE.toLowerCase()
  ) {
    return mergeRowsForBarAndMetricChart(originalRows, compareRows);
  }
  return [];
};

const comparePrevDayDataForChart = (
  originalChartData: LineChartData[],
  compareChartData: LineChartData[],
  originalToCompareMap?: Record<string, string>,
) => {
  const finalChartData: LineChartData[] = [];
  originalChartData.forEach((originalObj, _index) => {
    const compareObj = compareChartData.find((c: any) => c.key === addPastFlagToCompareString(originalObj.key, true));
    finalChartData.push({ ...originalObj, data: originalObj.data.map((d) => ({ ...d, compareDate: d.date })) });
    if (compareObj !== undefined) {
      const compareData = originalObj.data.map((d, idx) => {
        if (originalToCompareMap) {
          const originalDate = dayjs(d.date * 1000).format(DATETIME_FORMAT_WITH_TIMEZONE);
          const compareDate = dayjs(originalToCompareMap[originalDate]).unix();
          const compareValue = compareObj.data.find((item) => item.date === compareDate) ?? {
            date: compareDate,
            value: 0,
          };
          return { ...compareValue, compareDate: d.date };
        }
        return { ...compareObj.data[idx], compareDate: d.date };
      });
      finalChartData.push({ ...compareObj, data: [...compareData], color: originalObj.color });
    }
  });
  return finalChartData;
};

export const mergeChartDataForLineChart = (
  originalChartData: LineChartData[],
  compareChartData: LineChartData[],
  originalToCompareMap?: Record<string, string>,
) => {
  const rows = comparePrevDayDataForChart(originalChartData, compareChartData, originalToCompareMap);
  return rows;
};

const mergeOriginalAndCompareChartData = (
  originalChartData: ChartData,
  compareChartData: ChartData,
  chartType: any,
  originalToCompareMap?: Record<string, string>,
) => {
  const ct = chartType?.toLowerCase();
  if (ct === ChartType.LINE.toLowerCase()) {
    return mergeChartDataForLineChart(
      originalChartData as LineChartData[],
      compareChartData as LineChartData[],
      originalToCompareMap,
    );
  }
  if (
    ct === ChartType.BAR.toLowerCase() ||
    ct === ChartType.METRIC.toLowerCase() ||
    ct === ChartType.TABLE.toLowerCase()
  ) {
    const mergedArray: any = [];
    (originalChartData as GenericChartData[]).forEach((o: any) => {
      const compareDataItem = (compareChartData as GenericChartData[]).find((c: any) => c.key === o.key);
      if (compareDataItem) {
        mergedArray.push({ ...compareDataItem, key: addPastFlagToCompareString(compareDataItem.key, true) });
      }
      mergedArray.push(o);
    });
    return mergedArray;
  }
  return [];
};

export const generateTableRowsAndChartData = (
  breakdowns: Breakdown[],
  formulas: Formula[],
  data: any,
  compare: string[] | undefined,
  chartType: ChartType,
  granularity: GranularityEnum,
  customCompareData: CustomCompareStateType | undefined,
  timestampData: QueryBuilderTimestampInfo | undefined,
): DataVizRowAndChart => {
  let formattedData: any = {};
  const originalFormattedData = getFormattedData(data.original, breakdowns, formulas, false, chartType);
  if (compare && compare.length === 2 && chartType !== ChartType.PIE) {
    const compareFormattedData = getFormattedData(data.compare, breakdowns, formulas, true, chartType);
    if (compareFormattedData.rows.length > 0) {
      const mergedRows = mergeOriginalAndCompareRows(
        originalFormattedData.rows,
        compareFormattedData.rows,
        compare,
        chartType,
        customCompareData,
        timestampData,
      );
      const mergedChartData = mergeOriginalAndCompareChartData(
        originalFormattedData.chartData,
        compareFormattedData.chartData,
        chartType,
        data.x_axis?.original_to_compare_map,
      );
      formattedData = { rows: mergedRows, chartData: mergedChartData };
    } else {
      formattedData = originalFormattedData;
    }
  } else {
    formattedData = originalFormattedData;
  }
  return formattedData;
};
