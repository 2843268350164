import * as TooltipPrimitive from '@radix-ui/react-tooltip';

import { cn } from '../utils';

interface TooltipProps {
  content: React.ReactNode;
  children: React.ReactElement<any>;
  contentClassname?: string;
  open?: boolean;
  defaultOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  side?: 'top' | 'right' | 'bottom' | 'left';
  delayDuration?: number;
  sideOffset?: number;
  alignOffset?: number;
  hideArrow?: boolean;
}
export function Tooltip({
  children,
  content,
  contentClassname,
  side = 'top',
  delayDuration = 100,
  open,
  defaultOpen,
  onOpenChange,
  sideOffset,
  alignOffset,
  hideArrow,
  ...props
}: TooltipProps) {
  return (
    <TooltipPrimitive.Provider delayDuration={delayDuration}>
      <TooltipPrimitive.Root open={open} onOpenChange={onOpenChange} defaultOpen={defaultOpen}>
        <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
        {content ? (
          <TooltipPrimitive.Content
            side={side}
            align="center"
            sideOffset={sideOffset}
            alignOffset={alignOffset}
            className={cn(
              'z-[1002] text-background max-w-200 break-words bg-foreground dark:bg-foreground-selected rounded-lg shadow-xl px-3 py-1 text-xs animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
              contentClassname,
            )}
            {...props}
          >
            {content}
            {hideArrow ? null : <TooltipPrimitive.Arrow className="fill-foreground dark:fill-foreground-selected" />}
          </TooltipPrimitive.Content>
        ) : null}
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  );
}
