import { ReactNode, useCallback, useState } from 'react';

import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from '.';

interface HoverableDropdownClassNames {
  trigger?: string;
  content?: string;
}

export interface HoverableDropdownProps {
  testId: string;
  trigger: ReactNode;
  classNames?: HoverableDropdownClassNames;
  side?: 'top' | 'right' | 'bottom' | 'left';
}

export function HoverableDropdown({
  testId,
  children,
  trigger,
  classNames = {},
  side = 'right',
}: React.PropsWithChildren<HoverableDropdownProps>) {
  const [isOpen, setIsOpen] = useState(false);
  const handlePointerEnter = useCallback(() => setIsOpen(true), []);
  const handlePointerLeave = useCallback(() => setIsOpen(false), []);
  return (
    <DropdownMenu open={isOpen}>
      <DropdownMenuTrigger
        onPointerEnter={handlePointerEnter}
        onPointerLeave={handlePointerLeave}
        className={classNames?.trigger}
        data-testid={testId}
      >
        {trigger}
      </DropdownMenuTrigger>
      <DropdownMenuContent
        onPointerEnter={handlePointerEnter}
        onPointerLeave={handlePointerLeave}
        side={side}
        className={classNames?.content}
      >
        {children}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
