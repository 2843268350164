/* eslint-disable no-param-reassign */
import { DateRangeEnum, FlowsEventFilterType, FunnelMeasureTypes } from '@src/client/helpers/reports/constants';
import {
  getCustomDateRangeFormObjReportResponse,
  getFilterFormObjFromReportResponse,
  getFilterMapping,
  getFormattedDimensionFilters,
  getFormattedGlobalFilters,
  getFormattedTimestampProps,
  getSinceDateRangeFormObjReportResponse,
} from '@src/client/helpers/reports/dataUtils';
import { Dimension, Filter, FlowQueryBuilder } from '@src/client/helpers/reports/types';
import { ReportItemResponse } from '@src/client/lib/api/types/response';
import { getDiffPropertiesBetweenTwoObjects, hasValidValues, isLengthyArray } from '@src/client/lib/utils';
import { isEqual } from 'lodash-es';

import { hasDimensionValueChanged } from '../../components/filters-and-selectors/dimension-filter/utils';
import {
  getValidFilters,
  hasGlobalFiltersChanged,
} from '../../components/filters-and-selectors/global-property-filter/utils';
import {
  FlowsApiRes,
  FlowsExpandedEvent,
  FlowsNodeExpandPropertyRes,
  FlowsStepsResponse,
  SankeyChartData,
  SankeyLink,
  SankeyNode,
} from './types';

export const OTHER_EVENT_NAME = '$Other_event';
export const DROPOFF_EVENT_NAME = '$Drop_off';

export const shouldRefetchFlowOnParamsChange = (
  builderData: FlowQueryBuilder,
  lastBuilderData: FlowQueryBuilder | undefined,
): boolean => {
  const changedFields = getDiffPropertiesBetweenTwoObjects(builderData, lastBuilderData || {}) as Array<
    keyof FlowQueryBuilder
  >;
  const flowsFieldsNotAffectingData = ['flowTitle', 'flowDescription'];
  if (
    !isLengthyArray(builderData.steps) ||
    isEqual(builderData, lastBuilderData) ||
    isEqual(changedFields, flowsFieldsNotAffectingData)
  ) {
    return false;
  }
  if (isLengthyArray(changedFields)) {
    const shouldFetchReport = changedFields.some((value) => {
      const changedData = builderData[value];

      if (value === 'steps') {
        return hasDimensionValueChanged(builderData.steps, lastBuilderData?.steps);
      }

      if (value === 'global-filters')
        return hasGlobalFiltersChanged(builderData['global-filters'], lastBuilderData?.['global-filters']);

      if (value === 'flowsEventFilterType') {
        return (
          !isEqual(builderData.flowsEventFilterType, lastBuilderData?.flowsEventFilterType) &&
          ((builderData.flowsEventFilterType === FlowsEventFilterType.INCLUDE_EVENTS &&
            !isEqual(builderData.flowsIncludeEvents, lastBuilderData?.flowsIncludeEvents)) ||
            (builderData.flowsEventFilterType === FlowsEventFilterType.EXCLUDE_EVENTS &&
              !isEqual(builderData.flowsExcludeEvents, lastBuilderData?.flowsExcludeEvents)))
        );
      }

      if (value === 'flowsIncludeEvents') {
        return (
          builderData.flowsEventFilterType === FlowsEventFilterType.INCLUDE_EVENTS &&
          !isEqual(builderData.flowsIncludeEvents, lastBuilderData?.flowsIncludeEvents)
        );
      }

      if (value === 'flowsExcludeEvents') {
        return (
          builderData.flowsEventFilterType === FlowsEventFilterType.EXCLUDE_EVENTS &&
          !isEqual(builderData.flowsExcludeEvents, lastBuilderData?.flowsExcludeEvents)
        );
      }

      if (value === 'flowsExpandedEvents') {
        return (
          !isEqual(builderData.flowsExpandedEvents, lastBuilderData?.flowsExpandedEvents) &&
          ((isLengthyArray(changedData) && (changedData as FlowsExpandedEvent[]).every((d) => d.event && d.property)) ||
            !isLengthyArray(changedData))
        );
      }

      const isValid =
        !!value &&
        (!!changedData || (!changedData && lastBuilderData?.[value])) &&
        !flowsFieldsNotAffectingData.includes(value) &&
        (hasValidValues(changedData) || (!hasValidValues(changedData) && hasValidValues(lastBuilderData?.[value])));

      return isValid;
    });

    return shouldFetchReport;
  }
  return false;
};

export const isFlowStepValid = (steps: Dimension[]): boolean => {
  if (steps.length < 1 || steps.length > 2) return false;

  // eslint-disable-next-line consistent-return
  steps.forEach((step: Dimension, stepIdx: number) => {
    if (stepIdx < 2) {
      if (!step.name || step.name === '') return false;
    }
  });

  return true;
};

export const getFlowCreateObjFromBuilderData = (builderData: FlowQueryBuilder): any => {
  const createFunnelObject: any = {};
  const params: any = {
    chart_type: 'sankey',
  };
  const itemName = builderData.flowTitle ?? 'Untitled Funnel';
  const itemDescription = builderData.flowDescription;
  const globalFilters = builderData['global-filters'];
  const {
    steps,
    dateRange,
    customDateRange,
    sinceDateRange,
    function: measureFunctionType,
    flowsRowCount,
    flowsIncludeEvents,
    flowsExcludeEvents,
    flowsExpandedEvents,
  } = builderData;
  const timeWindow = builderData.measure_time_window;
  const { granularity } = builderData;

  params.flow_limit = flowsRowCount;
  params.measure = {
    measured_as: FunnelMeasureTypes.CONVERSION,
    time_window: {
      value: timeWindow.number,
      unit: timeWindow.unit,
    },
    function: measureFunctionType,
  };

  const timestampProps = getFormattedTimestampProps(granularity, dateRange, customDateRange, sinceDateRange);
  params.timestamp_props = timestampProps;
  let filters = {};
  params.steps = steps.map((step) => {
    const filterList = getValidFilters(step.filter);
    if (filterList !== undefined && filterList.length > 0) {
      const filterObj: any = {};
      const filterConfig: any[] = [];
      const validFilters = getValidFilters(filterList);
      validFilters.forEach((f: Filter) => filterConfig.push(getFormattedDimensionFilters(f)));
      const filterMappingStr = getFilterMapping(validFilters);
      if (filterMappingStr !== '') filterObj.mapping = filterMappingStr;
      filterObj.config = filterConfig;
      filters = filterObj;
    }
    const stepObj: any = {
      ...step,
      name: step.name.split('|')[0],
      step_label: step.name.split('|')[0],
      event: step.name.split('|')[0],
      filters,
    };
    try {
      delete stepObj.filter;
    } catch (error) {
      console.log(error);
    }
    return stepObj;
  });
  params.included_events = flowsIncludeEvents?.map((evt) => evt.split('|')[0]);
  params.excluded_events = flowsExcludeEvents?.map((evt) => evt.split('|')[0]);
  params.expand_by_property = flowsExpandedEvents.map((evt) => ({
    event: evt.event,
    property_name: evt.property,
  }));
  params.additional_metrics = { metrics_list: ['average'] };

  if (globalFilters !== undefined && globalFilters.length > 0) {
    const formattedGlobalFilters = getFormattedGlobalFilters(globalFilters);
    if (formattedGlobalFilters.config.length > 0) {
      params['global-filters'] = formattedGlobalFilters;
    }
  }

  createFunnelObject.itemName = itemName;
  createFunnelObject.itemDescription = itemDescription;
  createFunnelObject.itemScope = 'PUBLIC';
  createFunnelObject.params = params;

  return createFunnelObject;
};

export const getBuilderDataFromFlowResponse = (response: ReportItemResponse): FlowQueryBuilder => {
  const globalFilters = response.params['global-filters'];
  const {
    steps,
    included_events: includedEvents,
    excluded_events: excludedEvents,
    flow_limit: flowLimit,
    expand_by_property: expandByProperty,
  } = response.params;

  const formvalues: Partial<FlowQueryBuilder> = {
    flowTitle: response.name ?? '',
    flowDescription: response.description ?? '',
    dateRange: response.params.timestamp_props.date_range_type
      ? response.params.timestamp_props.date_range_type.toLowerCase(``)
      : ``,
    granularity: response.params.timestamp_props ? response.params.timestamp_props.granularity : null,
    steps: steps.map((step: any) => {
      const { filters, dimension_label: dimesnionLabel } = step;
      const dimensionInfo = { ...step, name: `${step.name}` };
      if (filters && filters.mapping) {
        dimensionInfo['filter-mapping'] = filters.mapping;
      }
      if (dimesnionLabel && dimesnionLabel.length > 0) {
        dimensionInfo.dimension_label = dimesnionLabel;
      }
      dimensionInfo.filter =
        filters && filters.config && filters.config.length > 0 ? getFilterFormObjFromReportResponse(filters) : [];
      return dimensionInfo;
    }),
    flowsIncludeEvents: isLengthyArray(includedEvents) ? includedEvents.map((evt: string) => `${evt}`) : [],
    flowsExcludeEvents: isLengthyArray(excludedEvents) ? excludedEvents.map((evt: string) => `${evt}`) : [],
    flowsRowCount: flowLimit,
  };

  if (response.params.timestamp_props.date_range_type === DateRangeEnum.CUSTOM) {
    formvalues.customDateRange = getCustomDateRangeFormObjReportResponse(response.params.timestamp_props);
  }
  if (response.params.timestamp_props.date_range_type === DateRangeEnum.SINCE) {
    formvalues.sinceDateRange = getSinceDateRangeFormObjReportResponse(response.params.timestamp_props);
  }

  if (globalFilters && globalFilters.config && globalFilters.config.length > 0) {
    formvalues['global-filters'] = getFilterFormObjFromReportResponse(globalFilters);
  }

  if (response.params.measure) {
    formvalues.measure_time_window = {
      number: response.params.measure.time_window.value,
      unit: response.params.measure.time_window.unit,
    };
    formvalues.function = response.params.measure.function;
  }

  formvalues.flowsExpandedEvents = (expandByProperty || []).map((p: { event: string; property_name: string }) => ({
    event: p.event,
    property: p.property_name,
  }));

  return formvalues as FlowQueryBuilder;
};

const mergeAndSumDuplicateNodes = (nodes: SankeyNode[]): SankeyNode[] => {
  const mergedNodeData: Record<string, SankeyNode> = {};
  nodes.forEach((obj) => {
    const key = obj.id;
    if (!mergedNodeData[key]) {
      mergedNodeData[key] = { ...obj };
    } else {
      mergedNodeData[key].total += obj.total;
      mergedNodeData[key].percent += obj.percent;
    }
  });

  return Object.values(mergedNodeData).map((item) => ({ ...item, percent: Number(item.percent.toFixed(2)) }));
};

const mergeAndSumDuplicateLinksValues = (links: SankeyLink[]): SankeyLink[] => {
  const mergedLinkData: Record<string, SankeyLink> = {};
  links.forEach((obj) => {
    const key = `${obj.source}_${obj.target}`;
    if (!mergedLinkData[key]) {
      mergedLinkData[key] = { ...obj };
    } else {
      mergedLinkData[key].value += obj.value;
    }
  });

  return Object.values(mergedLinkData);
};

const getSuffixStringFromExpandbyProperty = (expandedPropertyList: FlowsNodeExpandPropertyRes[]): string =>
  expandedPropertyList.reduce((accm, item) => `${accm} | ${item.property_value}`, '');

const generateNodesFromApiSteps = (steps: FlowsStepsResponse[]): SankeyNode[] =>
  steps.reduce((accm: any, parentItem: FlowsStepsResponse) => {
    const nodesToPush: SankeyNode[] = parentItem.nodes.reduce((innerAccm, n) => {
      let idSuffix = '';
      if (isLengthyArray(n.expand_by_property)) {
        idSuffix = getSuffixStringFromExpandbyProperty(n.expand_by_property!);
      }
      const tempNode: SankeyNode = {
        id: isLengthyArray(n.expand_by_property)
          ? `${parentItem.step_order}-${n.event}-${idSuffix}`
          : `${parentItem.step_order}-${n.event}`,
        event: isLengthyArray(n.expand_by_property) ? `${n.event}${idSuffix}` : n.event,
        total: n.total,
        percent: n.percent,
        step_name: parentItem.step_name,
        step_order: parentItem.step_order,
        type: n.type,
      };
      return [...innerAccm, tempNode];
    }, [] as SankeyNode[]);

    const uniqueNodes = mergeAndSumDuplicateNodes(nodesToPush);
    return [...accm, ...uniqueNodes];
  }, []);

const generateLinks = (
  steps: FlowsStepsResponse[],
  nodes: SankeyNode[],
): { links: SankeyLink[]; nodes: SankeyNode[] } => {
  const links: SankeyLink[] = [];
  steps.forEach((step, index) => {
    if (index > 0) {
      step.nodes.forEach((node) => {
        let originalSource = `${step.step_order - 1}-${node.source_event.event_name}`;
        if (isLengthyArray(node.source_event.expand_by_property)) {
          const idSuffix = getSuffixStringFromExpandbyProperty(node.source_event.expand_by_property);
          originalSource = `${originalSource}-${idSuffix}`;
        }

        const sourceNodeId = nodes.findIndex((n) => n.id === originalSource) > -1 ? originalSource : null;

        let targetnodeid = `${step.step_order}-${node.event}`;
        if (isLengthyArray(node.expand_by_property)) {
          const targetNodeIdSuffix = getSuffixStringFromExpandbyProperty(node.expand_by_property!);
          targetnodeid = `${targetnodeid}-${targetNodeIdSuffix}`;
        }
        if (sourceNodeId) {
          links.push({
            source: sourceNodeId,
            target: targetnodeid,
            value: node.total,
            sourceStepIndex: step.step_order - 1,
            targetStepIndex: step.step_order,
          });
        }
      });
    }
  });

  const uniqueLinks = mergeAndSumDuplicateLinksValues(links);
  return { links: uniqueLinks, nodes };
};

export const formatFlowsAPiRespToSankeyChartData = (
  data: FlowsApiRes,
  stepsBreakpointsForDummyEvent: number[],
  anchorSteps: number[],
): SankeyChartData => {
  const {
    original: { steps },
  } = data;
  const stepCount = steps.length;
  const sortedSteps = steps.sort((a, b) => a.step_order - b.step_order);

  const tempSankeyNodes: SankeyNode[] = generateNodesFromApiSteps(sortedSteps);

  const { links, nodes } = generateLinks(sortedSteps, tempSankeyNodes);

  return {
    nodes: nodes.sort((a, b) => a.step_order - b.step_order),
    links,
  };
};
