import { DimensionType } from '@src/client/lib/api/types/response';
import { isLengthyArray } from '@src/client/lib/utils';
import { isEqual } from 'lodash-es';

import { DimensionMathValues } from '../../../helpers/reports/constants';
import {
  Dimension,
  EventsAndCohorts,
  Filter,
  FilterPropertyType,
  FunnelCompareStep,
  FunnelStep,
} from '../../../helpers/reports/types';
import { numberToAlphabets } from '../../../helpers/reports/uiHelpers';
import { FILTER_CONECTORS, FILTER_OPERATORS } from '../global-property-filter/constants';
import { getUpdatedFilters, getValidFilters } from '../global-property-filter/utils';

// const checkEventExistsInFunnelStep = (items: FunnelStep[] = []) => {
//   const eventExists = items.every((item) => {
//     if (isLengthyArray(item.compare)) {
//       return item.compare?.every((compareItem) => compareItem.event && compareItem.event !== '');
//     }
//     return item.event && item.event !== '';
//   });
//   return eventExists;
// };

export const transformFunnelStepsToDimensions = (funnelSteps: FunnelStep[]): Dimension[] =>
  funnelSteps.map(
    (f, index) =>
      ({
        alias: String.fromCharCode(65 + index),
        first_time_filter: isLengthyArray(f.compare) ? !!f.compare![0]!.first_time_filter : !!f.first_time_filter,
        name: isLengthyArray(f.compare) ? f.compare![0]!.event : f.event!,
        math: [DimensionMathValues.TOTAL],
        filter: (isLengthyArray(f.compare) ? f.compare![0]!.filter : f.filter) ?? [],
        resource_type: DimensionType.EVENT,
      }) as Dimension,
  );

export const transformDimensionsToFunnelSteps = (dimensions: Dimension[]): FunnelStep[] =>
  dimensions
    .filter((d) => d.resource_type === DimensionType.EVENT)
    .map(
      (d, index) =>
        ({
          alias: `${index + 1}`,
          first_time_filter: !!d.first_time_filter,
          event: d.name,
          filter: d.filter ?? [],
          step_label: `${index + 1} ${d.name}`,
        }) as FunnelStep,
    );

const doesFunnelStepHasValidEvents = (item: FunnelStep) => {
  if (isLengthyArray(item.compare)) {
    return item.compare?.every((compareItem) => compareItem.event && compareItem.event !== '');
  }
  return item.event && item.event !== '';
};

export const hasFunnelStepsValueChanged = (
  steps: FunnelStep[] | undefined,
  previousSteps: FunnelStep[] | undefined,
): boolean => {
  const validCurrentSteps = (steps ?? []).filter((step) => doesFunnelStepHasValidEvents(step));
  const validPrevSteps = (previousSteps ?? []).filter((step) => doesFunnelStepHasValidEvents(step));

  const validCurrentStepsNameString = (steps ?? []).reduce(
    (accm, currrentStep) =>
      accm + (currrentStep.compare ? currrentStep.compare.map((c) => c.event).join(',') : currrentStep.event),
    '',
  );
  const validPrevStepsNameString = (previousSteps ?? []).reduce(
    (accm, currrentStep) =>
      accm + (currrentStep.compare ? currrentStep.compare.map((c) => c.event).join(',') : currrentStep.event),
    '',
  );

  const validCurrentStepsLabelString = (steps ?? []).reduce(
    (accm, currrentStep) =>
      accm + (currrentStep.compare ? currrentStep.compare.map((c) => c.step_label).join(',') : currrentStep.step_label),
    '',
  );
  const validPrevStepsLabelString = (previousSteps ?? []).reduce(
    (accm, currrentStep) =>
      accm + (currrentStep.compare ? currrentStep.compare.map((c) => c.step_label).join(',') : currrentStep.step_label),
    '',
  );

  if (!isLengthyArray(validCurrentSteps)) return false;

  if (!isEqual(validCurrentStepsNameString, validPrevStepsNameString)) {
    return true;
  }

  if (!isEqual(validCurrentStepsLabelString, validPrevStepsLabelString)) {
    return true;
  }

  const hasFiltersChanged = () =>
    validCurrentSteps?.some((currentStep, idx) => {
      const prevStep = validPrevSteps?.[idx];
      let prevStepFilterString = '';
      if (prevStep?.compare) {
        prevStepFilterString = prevStep?.compare?.reduce(
          (accm, compareStep) => accm + JSON.stringify(getValidFilters(compareStep.filter ?? [])),
          '',
        );
      } else {
        prevStepFilterString = JSON.stringify(getValidFilters(prevStep?.filter ?? []));
      }

      let currentStepFilterString = '';
      if (currentStep.compare) {
        currentStepFilterString = currentStep.compare?.reduce(
          (accm, compareStep) => accm + JSON.stringify(getValidFilters(compareStep?.filter ?? [])),
          '',
        );
      } else {
        currentStepFilterString = JSON.stringify(getValidFilters(currentStep?.filter ?? []));
      }

      return !isEqual(currentStepFilterString, prevStepFilterString);
    });

  const hasFirstTimeFilterChanged = () =>
    !isEqual(
      validCurrentSteps.map((d) => (d.compare ? d.compare.map((c) => c.first_time_filter) : d.first_time_filter)),
      validPrevSteps.map((d) => (d.compare ? d.compare.map((c) => c.first_time_filter) : d.first_time_filter)),
    );

  return hasFiltersChanged() || hasFirstTimeFilterChanged();
};

export const isExplicitStepLabel = (
  stepObject: FunnelStep,
  compareMode: boolean,
  compareStepIndex?: number,
): boolean => {
  if (compareMode && isLengthyArray(stepObject.compare)) {
    if (
      stepObject.compare![compareStepIndex!].step_label &&
      stepObject.compare![compareStepIndex!].step_label !==
        `${stepObject.compare![compareStepIndex!].alias} ${stepObject.compare![compareStepIndex!].event}`
    ) {
      return true;
    }
    return false;
  }
  if (stepObject.step_label && stepObject.step_label !== `${stepObject.alias} ${stepObject.event}`) {
    return true;
  }
  return false;
};

export function initializeLabelName(
  funnelSteps: FunnelStep[],
  stepIdx: number,
  compareMode: boolean,
  compareStepIndex?: number,
) {
  if (compareMode && isExplicitStepLabel(funnelSteps[stepIdx], compareMode, compareStepIndex)) {
    return funnelSteps[stepIdx].compare![compareStepIndex!].step_label;
  }
  if (!compareMode && isExplicitStepLabel(funnelSteps[stepIdx], compareMode, compareStepIndex)) {
    return funnelSteps[stepIdx].step_label;
  }
  return '';
}

export function addFilterInFunnelStep(
  oldSteps: FunnelStep[],
  stepIdx: number,
  compareMode: boolean,
  compareStepIndex?: number,
): FunnelStep[] {
  if (!compareMode) {
    const newSteps = [...oldSteps];
    const filterCount = newSteps[stepIdx].filter ? newSteps[stepIdx].filter!.length : 0;
    const filterToAdd: Filter = {
      key: filterCount + 1,
      property: '',
      resource_type: FilterPropertyType.EVENT,
      operator: FILTER_OPERATORS[0],
      values: [],
    };

    if (newSteps[stepIdx].filter && isLengthyArray(newSteps[stepIdx].filter)) {
      filterToAdd.map = FILTER_CONECTORS[0]; // eslint-disable-line prefer-destructuring
    }

    newSteps[stepIdx] = {
      ...newSteps[stepIdx],
      filter: newSteps[stepIdx].filter ? [...newSteps[stepIdx].filter!, filterToAdd] : [filterToAdd],
    };
    return newSteps;
  }
  if (!compareStepIndex && compareStepIndex !== 0) {
    throw new Error('Compare Step Index is necessary when using addFilterInFunnelStep in compare mode');
  }
  const newSteps = [...oldSteps];
  const filterCount = newSteps[stepIdx].compare![compareStepIndex!].filter
    ? newSteps[stepIdx].compare![compareStepIndex!].filter!.length
    : 0;
  const filterToAdd: Filter = {
    key: filterCount + 1,
    property: '',
    resource_type: FilterPropertyType.EVENT,
    operator: FILTER_OPERATORS[0],
    values: [],
  };

  if (
    newSteps[stepIdx].compare![compareStepIndex!].filter &&
    isLengthyArray(newSteps[stepIdx].compare![compareStepIndex!])
  ) {
    filterToAdd.map = FILTER_CONECTORS[0]; // eslint-disable-line prefer-destructuring
  }

  const compareArr = [...newSteps[stepIdx].compare!];
  compareArr[compareStepIndex!] = {
    ...compareArr[compareStepIndex!],
    filter: compareArr[compareStepIndex!].filter
      ? [...compareArr[compareStepIndex!].filter!, filterToAdd]
      : [filterToAdd],
  };
  newSteps[stepIdx] = { ...newSteps[stepIdx], compare: compareArr };
  return newSteps;
}

export function updateFilterInFunnelStep(
  oldSteps: FunnelStep[],
  stepIdx: number,
  compareMode: boolean,
  filter: Filter,
  filterIndex: number,
  compareStepIndex?: number,
): FunnelStep[] {
  if (!compareMode) {
    const newSteps = [...oldSteps];
    newSteps[stepIdx] = {
      ...newSteps[stepIdx],
      filter: oldSteps[stepIdx].filter ? getUpdatedFilters(newSteps[stepIdx].filter!, filter, filterIndex) : [],
    };
    return newSteps;
  }
  const newSteps = [...oldSteps];
  const compareArr = [...newSteps[stepIdx].compare!];
  compareArr[compareStepIndex!] = {
    ...compareArr[compareStepIndex!],
    filter: compareArr[compareStepIndex!].filter
      ? getUpdatedFilters(compareArr[compareStepIndex!].filter!, filter, filterIndex)
      : [],
  };
  newSteps[stepIdx] = { ...newSteps[stepIdx], compare: compareArr };
  return newSteps;
}

export function removeFilterFromFunnelStep(
  oldSteps: FunnelStep[],
  stepIdx: number,
  compareMode: boolean,
  filterIndex: number,
  compareStepIndex?: number,
): FunnelStep[] {
  if (!compareMode) {
    const newSteps = [...oldSteps];
    newSteps[stepIdx] = {
      ...newSteps[stepIdx],
      filter: newSteps[stepIdx].filter!.filter((_, i) => i !== filterIndex).map((f, i) => ({ ...f, key: i + 1 })),
    };
    return newSteps;
  }
  const newSteps = [...oldSteps];
  const compareArr = [...newSteps[stepIdx].compare!];
  compareArr[compareStepIndex!] = {
    ...compareArr[compareStepIndex!],
    filter: compareArr[compareStepIndex!]
      .filter!.filter((_, i) => i !== filterIndex)
      .map((f, i) => ({ ...f, key: i + 1 })),
  };
  newSteps[stepIdx] = {
    ...newSteps[stepIdx],
    compare: compareArr,
  };
  return newSteps;
}

// only used when step count is more than 2
export function removeStepFromFunnelSteps(
  oldSteps: FunnelStep[],
  stepIdx: number,
  compareMode: boolean,
  compareStepIndex?: number,
): FunnelStep[] {
  if (!compareMode) {
    let newSteps = [...oldSteps];
    newSteps.splice(stepIdx, 1);
    newSteps = newSteps.map((d, i) => ({
      ...d,
      alias: `${i + 1}`,
      step_label: isExplicitStepLabel(d, false) ? d.step_label : `${i + 1} ${d.event}`,
    }));
    return newSteps;
  }

  if (compareStepIndex === undefined) return oldSteps; // This should never happend. Compare mode should always have compareStepIndex

  const newSteps = [...oldSteps];
  const compareArr = [...newSteps[stepIdx].compare!];

  compareArr.splice(compareStepIndex!, 1);
  newSteps[stepIdx] = {
    ...newSteps[stepIdx],
    compare: compareArr.map((item, index) => ({
      ...item,
      alias: `${stepIdx + 1}${numberToAlphabets(index)}`,
      step_label: `${stepIdx + 1}${numberToAlphabets(index)} ${item.step_label.slice(3)}`,
    })),
  };

  if (newSteps[stepIdx].compare?.length === 1) {
    newSteps[stepIdx] = {
      event: newSteps[stepIdx].compare![0].event,
      event_type: newSteps[stepIdx].compare![0].event_type,
      first_time_filter: newSteps[stepIdx].compare![0].first_time_filter,
      step_label: `${stepIdx + 1} ${newSteps[stepIdx].compare![0].step_label.slice(3)}`,
      filter: newSteps[stepIdx].compare![0].filter,
      alias: (stepIdx + 1).toString(),
    };
  }
  return newSteps;
}

export function handleStepChangesInFunnelSteps(
  key: 'first_time_filter' | 'event' | 'custom_event' | 'step_label' | 'is_excluded',
  value: string | boolean | string[] | EventsAndCohorts,
  oldSteps: FunnelStep[],
  stepIdx: number,
  compareMode: boolean,
  compareStepIndex?: number,
): FunnelStep[] {
  // For non-compare mode
  if (!compareMode) {
    const newSteps = [...oldSteps];
    if (key === 'custom_event' && typeof value === 'object' && 'value' in value) {
      newSteps[stepIdx] = {
        ...newSteps[stepIdx],
        event: value.value,
      };

      newSteps[stepIdx].event_type = DimensionType.CUSTOM_EVENT;
      newSteps[stepIdx].step_label = `${newSteps[stepIdx].alias} ${value.label as string}`;

      return newSteps;
    }
    if (key !== 'step_label') {
      newSteps[stepIdx] = {
        ...newSteps[stepIdx],
        [key]: value,
      };
    }
    if (key === 'event') {
      newSteps[stepIdx].event_type = DimensionType.EVENT;
      newSteps[stepIdx].step_label = `${newSteps[stepIdx].alias} ${value as string}`;
    }
    if (key === 'step_label') {
      const step = { ...newSteps[stepIdx] };
      const label = step.step_label;
      step.step_label =
        !value || value === ''
          ? `${step.alias} ${label?.slice(2) as string}`
          : `${step.alias} ${value}(${label?.slice(2) as string})`;

      newSteps[stepIdx] = step;
    }
    return newSteps;
  }

  // For compare mode
  const newSteps = [...oldSteps];
  const newCompareSteps: FunnelCompareStep[] = [...(newSteps[stepIdx].compare ? newSteps[stepIdx].compare! : [])];
  if (key === 'custom_event' && typeof value === 'object' && 'value' in value) {
    newCompareSteps[compareStepIndex!] = { ...newCompareSteps[compareStepIndex!], event: value.value };
    newSteps[stepIdx] = {
      ...newSteps[stepIdx],
      event: value.value,
    };

    newCompareSteps[compareStepIndex!].event_type = DimensionType.CUSTOM_EVENT;
    newCompareSteps[compareStepIndex!].step_label =
      `${newCompareSteps[compareStepIndex!].alias} ${value.label as string}`;

    newSteps[stepIdx] = {
      ...newSteps[stepIdx],
      compare: newCompareSteps,
    };

    return newSteps;
  }
  if (key !== 'step_label') {
    newCompareSteps[compareStepIndex!] = { ...newCompareSteps[compareStepIndex!], [key]: value };
  }
  if (key === 'event') {
    newCompareSteps[compareStepIndex!].event_type = DimensionType.EVENT;
    newCompareSteps[compareStepIndex!].step_label = `${newCompareSteps[compareStepIndex!].alias} ${value as string}`;
  }
  if (key === 'step_label') {
    const step = { ...newCompareSteps[compareStepIndex!] };
    const label = step.step_label;
    step.step_label =
      !value || value === ''
        ? `${step.alias} ${label?.slice(2) as string}`
        : `${step.alias} ${value}(${label?.slice(2) as string})`;

    newCompareSteps[compareStepIndex!] = step;
  }
  if (key === 'is_excluded' && typeof value === 'boolean') {
    newSteps[stepIdx] = {
      ...newSteps[stepIdx],
      compare: newCompareSteps.map((step) => ({ ...step, [key]: value })),
    };
    return newSteps;
  }
  newSteps[stepIdx] = {
    ...newSteps[stepIdx],
    compare: newCompareSteps,
  };
  return newSteps;
}

export function checkStepLabelAlreadyExists(allSteps: FunnelStep[], new_step_label: string): boolean {
  return allSteps.some((funnelStep) => {
    if (funnelStep.compare) {
      return funnelStep.compare.some((funnelCompareStep) => funnelCompareStep.step_label === new_step_label);
    }
    return funnelStep.step_label === new_step_label;
  });
}

export function enableCompareInFunnelSteps(
  oldSteps: FunnelStep[],
  stepIdx: number,
  compareMode: boolean,
): FunnelStep[] {
  if (!compareMode) {
    const newSteps = [...oldSteps];
    newSteps[stepIdx] = {
      ...newSteps[stepIdx],
      compare: [
        {
          event: newSteps[stepIdx].event ? newSteps[stepIdx].event : undefined,
          event_type: newSteps[stepIdx].event_type ? newSteps[stepIdx].event_type : DimensionType.EVENT,
          first_time_filter: newSteps[stepIdx].first_time_filter ? newSteps[stepIdx].first_time_filter! : false,
          step_label: newSteps[stepIdx].step_label
            ? `${stepIdx + 1}${numberToAlphabets(0)}${newSteps[stepIdx].step_label.slice(1)}`
            : '',
          filter: newSteps[stepIdx].filter ? newSteps[stepIdx].filter! : [],
          alias: `${stepIdx + 1}${numberToAlphabets(0)}`,
        },
        {
          event: undefined,
          event_type: DimensionType.EVENT,
          first_time_filter: false,
          step_label: '',
          filter: [],
          alias: `${stepIdx + 1}${numberToAlphabets(1)}`,
        },
      ],
    };
    return newSteps;
  }
  const newSteps = [...oldSteps];
  newSteps[stepIdx] = {
    ...newSteps[stepIdx],
    compare: [
      ...(isLengthyArray(newSteps[stepIdx].compare) ? newSteps[stepIdx].compare! : []),
      {
        event: undefined,
        event_type: DimensionType.EVENT,
        first_time_filter: false,
        step_label: '',
        filter: [],
        alias: `${stepIdx + 1}${numberToAlphabets(
          (isLengthyArray(newSteps[stepIdx].compare) ? newSteps[stepIdx].compare! : []).length,
        )}`,
      },
    ],
  };
  return newSteps;
}
