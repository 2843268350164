// NOTE: Radix primittives don't support multi select right now. Using 'react-select' for now. Some other inspiration using 'cmdk' package and other radix primitives
// https://github.com/mxkaske/mxkaske.dev/blob/main/components/craft/fancy-multi-select.tsx
// https://craft.mxkaske.dev/post/fancy-multi-select

import { matchSorter } from 'match-sorter';
import React, { useEffect, useState } from 'react';
import ReactSelect, { components as ReactSelectComponents, createFilter } from 'react-select';

import { commonClassNames, commonStyles } from './commonSelectStyles';
import { CustomMultiValue, CustomOption, NoOptionsMessageSync } from './CustomSelectComponents';
import { SelectClassNames } from './types';
import { VirtualMenuList } from './VirtualMenuList';

export const Select = React.forwardRef<
  React.ElementRef<typeof ReactSelect>,
  React.ComponentPropsWithoutRef<typeof ReactSelect> & {
    selectClassNames?: SelectClassNames;
    useVirtualMenuList?: boolean;
  }
>(
  (
    {
      isMulti,
      autoFocus,
      value,
      isLoading,
      options,
      menuIsOpen,
      placeholder,
      selectClassNames,
      onChange,
      onBlur,
      components = {},
      useVirtualMenuList,
      ...props
    },
    ref,
  ) => {
    const [selectOptions, setSelectOptions] = useState(options);

    useEffect(() => {
      setSelectOptions(options);
    }, [options]);

    return (
      <ReactSelect
        ref={ref}
        isMulti={isMulti}
        closeMenuOnSelect={!isMulti}
        autoFocus={autoFocus}
        openMenuOnFocus
        hideSelectedOptions={false}
        value={value}
        isLoading={isLoading}
        onChange={onChange}
        options={selectOptions}
        onInputChange={(inputValue) => {
          setSelectOptions(matchSorter(options || [], inputValue, { keys: ['label'] }));
        }}
        unstyled
        onBlur={onBlur}
        menuIsOpen={menuIsOpen}
        placeholder={placeholder}
        components={{
          Option: CustomOption,
          NoOptionsMessage: NoOptionsMessageSync,
          MultiValue: CustomMultiValue,
          MenuList: useVirtualMenuList ? VirtualMenuList : ReactSelectComponents.MenuList,
          ...components,
        }}
        filterOption={createFilter({ ignoreAccents: false })}
        styles={commonStyles}
        classNames={commonClassNames(selectClassNames)}
        {...props}
      />
    );
  },
);

Select.displayName = 'Select';
