import { useIsDemoWorkspace } from '@src/client/hooks';
import { userInfoState } from '@src/client/recoil/atoms';
import { DropdownMenuSeparator } from '@src/client/ui-library/dropdown';
import { cn } from '@src/client/ui-library/utils';
import { useRecoilState } from 'recoil';

import { EngageIconV3, FlexicastIconV3, TemplatesIconV3 } from '../../ui-library/icons/v3';
import { ELEVATED_ACCESS_ROLES, MARKETING_ANALYST } from '../site-navbar/constants';
import { SidepanelNavLinkClassNames } from './constants';
import FeatureLock from './FeatureLock';
import LinkInfoTooltip from './LinkInfoTooltip';
import NestedNavigationMenu from './NestedNavigationMenu';
import SidepanelNavLink from './SidepanelNavLink';
import SidepanelNavlinkTooltip from './SidepanelNavlinkTooltip';

export default function EngageMenu({
  isNavigationSidepanelOpen,
  currentPathname,
}: {
  isNavigationSidepanelOpen: boolean;
  currentPathname: string;
}) {
  const isFlexicastOpen = currentPathname.includes('flexicast/list') || currentPathname.includes('flexicast/create');
  const isTemplateGeneratorOpen = currentPathname.includes('engage/create-template');
  const isCampaignOpen =
    (currentPathname.includes('engage/list') || currentPathname.includes('engage/create')) && !isTemplateGeneratorOpen;
  const isEngageOpen = isFlexicastOpen || isCampaignOpen || isTemplateGeneratorOpen;
  const isDemoWorkspace = useIsDemoWorkspace();
  const [userInfo] = useRecoilState(userInfoState);

  if (isDemoWorkspace || ![...ELEVATED_ACCESS_ROLES, MARKETING_ANALYST].includes(userInfo?.roleName ?? '')) {
    return (
      <div
        className={cn(
          `w-full bg-transparent`,
          SidepanelNavLinkClassNames,
          isNavigationSidepanelOpen ? 'py-2 px-5' : 'px-4 py-2 justify-center',
          'cursor-not-allowed rounded-none',
        )}
      >
        <LinkInfoTooltip content={<p>Please reach out to Percept team for access</p>} side="right" sideOffset={12}>
          <div className="w-full">
            {isNavigationSidepanelOpen ? (
              <div className="flex items-center justify-between w-full">
                <div className="inline-flex items-center space-x-2">
                  <EngageIconV3 />
                  <p className="text-sm font-medium">Engage</p>
                  <FeatureLock isNavigationSidepanelOpen={isNavigationSidepanelOpen} />
                </div>
              </div>
            ) : (
              <div className="relative">
                <EngageIconV3 />
                <FeatureLock isNavigationSidepanelOpen={isNavigationSidepanelOpen} />
              </div>
            )}
          </div>
        </LinkInfoTooltip>
      </div>
    );
  }

  return (
    <NestedNavigationMenu
      isNavigationSidepanelOpen={isNavigationSidepanelOpen}
      triggerText="Engage"
      isActive={isEngageOpen}
      triggerIcon={<EngageIconV3 active={isEngageOpen} />}
      tooltipContent={
        <SidepanelNavlinkTooltip
          icon={<EngageIconV3 active />}
          description={isNavigationSidepanelOpen ? 'Campaign creation, management, attribution' : 'Engage'}
        />
      }
    >
      <SidepanelNavLink
        isNavigationSidepanelOpen
        path="engage/list"
        text="Campaigns"
        icon={<EngageIconV3 active={isCampaignOpen} />}
        tooltipContent={
          <SidepanelNavlinkTooltip
            icon={<EngageIconV3 active />}
            description="Engage with customers basis their behaviour near real time, configure reminders, build campaigns."
          />
        }
      />
      <DropdownMenuSeparator className="px-2 my-2" />
      <SidepanelNavLink
        isNavigationSidepanelOpen
        path="flexicast/list"
        text="Flexicast"
        icon={<FlexicastIconV3 active={isFlexicastOpen} />}
        tooltipContent={
          <SidepanelNavlinkTooltip
            icon={<FlexicastIconV3 active />}
            description="Create custom campaigns targeting user groups to boost conversions."
          />
        }
      />
      <DropdownMenuSeparator className="px-2 my-2" />
      <SidepanelNavLink
        isNavigationSidepanelOpen
        path="engage/create-template"
        text="Templates"
        icon={<TemplatesIconV3 active={isTemplateGeneratorOpen} />}
        tooltipContent={
          <SidepanelNavlinkTooltip
            icon={<TemplatesIconV3 active />}
            description="Use or design pre-made templates for streamlined campaign launches."
          />
        }
      />
    </NestedNavigationMenu>
  );
}
