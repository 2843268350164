import { HoverableDropdown } from '@src/client/ui-library/dropdown/HoverableDropdown';
import { cn } from '@src/client/ui-library/utils';
import { PropsWithChildren, ReactNode } from 'react';

import LucidIconWrapper from '../lucid-icon-wrapper';
import { SidepanelActiveNavClassNames, SidepanelNavLinkClassNames } from './constants';

interface Props extends PropsWithChildren {
  isNavigationSidepanelOpen: boolean;
  triggerText: string;
  triggerIcon: ReactNode;
  isActive: boolean;
  tooltipContent?: React.ReactNode;
}

export default function NestedNavigationMenu({
  isNavigationSidepanelOpen,
  children,
  triggerText,
  triggerIcon,
  isActive,
  tooltipContent,
}: Props) {
  return (
    <HoverableDropdown
      testId={`${triggerText.replaceAll(' ', '-')}-nav-button`}
      trigger={
        <div className="w-full">
          {isNavigationSidepanelOpen ? (
            <div className="flex items-center justify-between w-full">
              <div className="inline-flex items-center space-x-2">
                {triggerIcon}
                <p className="text-sm font-medium">{triggerText}</p>
              </div>
              <LucidIconWrapper name="chevron-right" />
            </div>
          ) : (
            triggerIcon
          )}
        </div>
      }
      classNames={{
        trigger: cn(
          SidepanelNavLinkClassNames,
          isNavigationSidepanelOpen ? 'py-2 px-5' : 'px-4 py-2 justify-center',
          isActive ? SidepanelActiveNavClassNames : '',
          `w-full bg-transparent rounded-none`,
        ),
        content: 'w-[260px] -ml-1 p-2 shadow-none rounded-l-none',
      }}
    >
      {children}
    </HoverableDropdown>
  );
}
